import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from 'src/app/pages/common/api.service';
import * as bannerActions from '../actions/banners.action';

@Injectable()

export class BannersEffects{
  constructor(
    private actions$: Actions,
    private ApiService: ApiService
  ){}

  loadBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bannerActions.getBanners),
      // exhaustMap((a) =>
      switchMap((action) =>
        this.ApiService.getBanners(action.params).pipe(
          map(response => bannerActions.bannersSuccess(response)),
          catchError((error: any) => of(bannerActions.bannersFailure(error))))
      )
    ),{dispatch: true}
  );
}
