import { MatDialog } from "@angular/material/dialog";
import { Injectable, EventEmitter } from "@angular/core";
// import { HttpClient } from './http-client';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { User } from "./user.modal";

import { GlobalConstants } from "./global-variables";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public authModalOpenEvent: Subject<boolean> = new Subject<boolean>();
  public errorEvent: Subject<boolean> = new Subject<boolean>();
  public reloadInit: Subject<boolean> = new Subject<boolean>();
  public isUserLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isCardVerified: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  public isFirstPrivateCard = localStorage?.getItem("userCard")
    ? JSON.parse(localStorage.getItem("userCard"))?.attributes.display_name ==
      "FIRST Private Credit Card"
    : false;
  isAuthModalOpen = false;
  isAuthenticated: boolean = false;

  constructor(
    private http: HttpClient,
    public GlobalConstants: GlobalConstants,
    private matDialog: MatDialog
  ) {
    this.subscribeToErrorEvents();
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    if (
      this.GlobalConstants &&
      this.GlobalConstants.data &&
      this.GlobalConstants.data.api_version == "v2"
    ) {
      environment.corePath = environment.corePathV2;
      environment.offerPath = environment.offerPathV2;
    }

    this.isUserLoggedIn.subscribe((isLoggedIn: boolean) => {
      this.isAuthenticated = isLoggedIn;
    });
  }

  public checkForUserTokenExpiry() {
    if (
      localStorage.getItem("currentUser") &&
      !window.location.pathname.includes("sso_login")
    ) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
      if (currentUser && currentUser.token) {
        this.isAuthenticated = true;
        const token = JSON.parse(atob(currentUser.token.split(".")[1]));
        const expiresOn = new Date(token.exp * 1000);
        if (expiresOn < new Date(Date.now())) {
          this.setTokenExpiredState();
        } else {
          setTimeout(() => {
            this.setTokenExpiredState();
          }, Math.abs(Math.floor(new Date().getTime() - token.exp * 1000)));
        }
      }
    }
  }

  setTokenExpiredState() {
    localStorage.clear();
    this.errorEvent.next(true);
    this.isUserLoggedIn.emit(false);
  }

  public get currentUserValue(): any {
    return this.http.get<any>(environment.corePath + "/user");
    map((res) => {
      return res;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      });
  }

  getCurrentUser(bin, bin_type) {
    return this.http.get<any>(environment.corePath + "/user").pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }
  login(email: string, password: string) {
    return this.http
      .post<any>(environment.corePath + "/sessions", { email, password })
      .pipe(
        map((res) => {
          var user = {
            subdomain: res.data.relationships.target.links.subdomain,
            token: res.data.attributes.token,
          };
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateExistingUser(mobile: string, bin = "") {
    if (bin == "") {
      return this.http
        .post<any>(environment.corePath + "/users/search", { mobile })
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    } else {
      return this.http
        .post<any>(environment.corePath + "/users/search", { mobile, bin })
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
  }

  createUser(
    mobile: string,
    email: string,
    country_id: string = "default",
    calling_code
  ) {
    if (country_id == "default") {
      return this.http
        .post<any>(environment.corePath + "/users", {
          mobile: mobile,
          email: email,
          calling_code,
          country_id,
        })
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    } else {
      return this.http
        .post<any>(environment.corePath + "/users", {
          mobile: mobile,
          email: email,
          country_id: country_id,
        })
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
  }

  requestUserOtp(mobile: string, userId: string) {
    return this.http
      .post<any>(environment.corePath + "/users/" + userId + "/otps", {
        mobile: mobile,
        otp_type: "login",
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateUserOtp(
    userId: string,
    otp: string,
    otpType: string,
    mobileNum?: any
  ) {
    return this.http
      .post<any>(environment.corePath + "/users/" + userId + "/otps/verify", {
        otp_type: otpType,
        code: otp,
      })
      .pipe(
        map((res) => {
          var user = {
            id: userId,
            token: res.token,
          };
          if (mobileNum) {
            user["mobile"] = mobileNum;
          }
          if (user && user.token) {
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateExistingUserThroughConcierge(mobile: string, password: string) {
    return this.http
      .post<any>(environment.corePath + "/users/search", { mobile, password })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  externalRequestUserOtp(mobile: string) {
    return this.http
      .post<any>(environment.corePath + "/external_otps", { mobile: mobile })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  externalValidateUserOtp(otp: string, ref_no: string, mobileNum?: any) {
    return this.http
      .post<any>(environment.corePath + "/external_otps/verify", {
        ref_no: ref_no,
        code: otp,
      })
      .pipe(
        map((res) => {
          var user = {
            id: res.id,
            token: res.token,
          };
          if (mobileNum) {
            user["mobile"] = mobileNum;
          }
          if (user && user.token) {
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");
    localStorage.removeItem("cardSelected");
    localStorage.removeItem("eligible_banners");
    localStorage.removeItem("locationData");
    localStorage.removeItem("currentUserCard");
    sessionStorage.removeItem("points");
    sessionStorage.removeItem("client");
    localStorage.removeItem("userCard");
    localStorage.removeItem("urlcode");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("selectedCardType");
    localStorage.removeItem("customer_type");
    const offerToRedeem = localStorage.getItem("offerToRedeem");
    const firstLoad = localStorage.getItem("firstLoad");
    const returnUrl = localStorage.getItem("returnUrl");
    const clickedClaimGiftcard = sessionStorage.getItem("clickedClaimGiftcard");
    localStorage.clear();
    if (offerToRedeem) {
      localStorage.setItem("offerToRedeem", offerToRedeem);
    }
    if (firstLoad) {
      localStorage.setItem("firstLoad", offerToRedeem);
    }
    // storing beta key before clear session storage
    const beta = sessionStorage.getItem("beta");
    const betaCredit = sessionStorage.getItem("betaCredit");

    sessionStorage.clear();
    if (beta) {
      sessionStorage.setItem("beta", beta);
    }
    if (betaCredit) {
      sessionStorage.setItem("betaCredit", betaCredit);
    }
    if (returnUrl) {
      localStorage.setItem("returnUrl", returnUrl);
    }

    if (clickedClaimGiftcard) {
      sessionStorage.setItem("clickedClaimGiftcard", clickedClaimGiftcard);
    }

    this.currentUserSubject.next(null);
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem("currentUser"))) {
      var currentUserId = JSON.parse(localStorage.getItem("currentUser")).id;
    }
    return this.http
      .post<any>(environment.corePath + "/sessions/" + currentUserId, {})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  subscribeToErrorEvents() {
    this.errorEvent.subscribe((res) => {
      if (res && !this.matDialog.getDialogById("authenticationComponent")) {
        let customer_type = localStorage.getItem("customer_type");
        if (customer_type) {
          if (
            customer_type.toLowerCase() === "ntb" ||
            customer_type.toLowerCase() === "noetb" ||
            customer_type.toLowerCase() === "ntb-logout"
          ) {
            // this.customer_type = customer_type.toLowerCase();
            // this.returnUrl = localStorage.getItem("returnUrl")
            //   ? JSON.parse(localStorage.getItem("returnUrl"))
            //   : null;
            // this.showNtbUser = true;
          }
        } else if (localStorage.getItem("returnUrl")) {
          const returnUrl = JSON.parse(localStorage.getItem("returnUrl"));
          // localStorage.clear();
          // location.replace(returnUrl);
        } else if (localStorage.getItem("diningSearchParams")) {
          const diningSearchParams = localStorage.getItem("diningSearchParams");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("diningSearchParams", diningSearchParams);
          // this.restartLogin(true);
          // location.replace(returnUrl);\
          this.triggerLogin();
        } else {
          // this.restartLogin();
          this.triggerLogin();
        }
      }
    });
  }

  triggerLogin() {
    this.authModalOpenEvent.next(true);
  }
}
