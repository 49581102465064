import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { Template4FooterComponent } from "./template4-footer.component";


@NgModule({
  declarations: [Template4FooterComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [Template4FooterComponent]
})
export class Template4FooterModule { }
