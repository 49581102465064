import { Component, OnInit, HostListener } from '@angular/core';
import { GlobalConstants  } from 'src/app/pages/common/global-variables';
import moment from "moment";
@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
  providers: [GlobalConstants]
})
export class FooterComponent implements OnInit {
  currentYear;
  constructor( public GlobalConstants: GlobalConstants) {
    this.onResize()
  }

  ngOnInit() {
    this.currentYear = moment().format("YYYY");
  }
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
      // this.ngOnInit();
    }
    else if (window.innerWidth > 992) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
      // this.ngOnInit();
    }
    else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
      // this.ngOnInit();
    }
    // this.innerWidth = window.innerWidth;
  }
}
