import { createReducer, on } from '@ngrx/store';
import * as categoryActions from '../actions/categories.action';

export interface CategoriesState {
  categories: any;
  categoryDetails: any;
}
export const initialState: any = {};

export const categoriesReducer = createReducer(
  initialState,
  on(categoryActions.categoriesSuccess, (state, result) => ({categories: result})),
  // on(bannerActions.bannersFailure, (state, {result}) => [result])
  // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
);


export const categoriesDetailsReducer = createReducer(
  initialState,
  on(categoryActions.categoryDetailsSuccess, (state, result) => ({categoryDetails: result})),
  // on(bannerActions.bannersFailure, (state, {result}) => [result])
  // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
);
