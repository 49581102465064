import { createReducer, on } from '@ngrx/store';
import * as bannerActions from '../actions/banners.action';

export interface BannersState {
  banners: any;
}
export const initialState: any = {};

export const bannersReducer = createReducer(
  initialState,
  on(bannerActions.bannersSuccess, (state, result) => ({banners: result})),
  // on(bannerActions.bannersFailure, (state, {result}) => [result])
  // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
);
