// import { SharedModule } from 'src/app/pages/template4/shared/shared.module';
import { BrowserModule,HammerModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Layout1Component} from './layout/layouts/layout-1/layout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './pages/common/token.interceptor';
import { ErrorInterceptor } from './pages/common/error.interceptor';
import { GlobalConstants  } from './pages/common/global-variables';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FooterModule } from './layout/footer/footer.module';
import { Template4FooterModule } from './pages/template4/template4-footer/template4-footer.module';

import { SwUpdate } from '@angular/service-worker';
// ngrx related imports
import { StoreModule } from '@ngrx/store';

import { reducers, metaReducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { CountriesEffects } from './store/effects/countries.effects';
import { BannersEffects } from './store/effects/banners.effects';
import { CategoriesEffects } from './store/effects/categories.effects';
import { OffersEffects } from './store/effects/offers.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PaymentModule } from './pages/payment/payment.module';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

export const checkForUpdates = (swUpdate: SwUpdate): (() => Promise<any>) => {
  return (): Promise<void> =>
    new Promise((resolve:any) => {
      if(swUpdate.isEnabled){
        swUpdate.checkForUpdate();
        swUpdate.available.subscribe(() => {
            window.location.reload();
        });
      }
      resolve(true);
    });
};

@NgModule({
  declarations: [
    AppComponent,
    Layout1Component
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HammerModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
    }),
    // ngrx related imports
    StoreModule.forRoot(reducers, { metaReducers }),
    // StoreModule.forRoot({ countriesReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([CountriesEffects,BannersEffects,CategoriesEffects,OffersEffects]),
    // SharedModule,
    FooterModule,
    Template4FooterModule,
    PaymentModule.forRoot(environment),
    MatDialogModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    GlobalConstants,
    ToastrService,
    {
       provide: APP_INITIALIZER,
       useFactory: checkForUpdates,
       multi: true,
       deps: [SwUpdate] //dependencies
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
