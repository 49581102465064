import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Layout1Component } from "./layout/layouts/layout-1/layout.component";
import { OnlySwypGuard } from './only-swyp.guard';

const routes: Routes = [
  {
    path: 'sso_login',
    loadChildren: () => import("./pages/loginSso/loginSso.module").then(m => m.LoginSsoModule)
  },
  {
    path: "home",
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
  },
  {
    path: "",
    component: Layout1Component,
    children: [
      {
        path: 'offers',
        loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
      },
      {
        path: 'offers/:category',
        loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
      },
      {
        path: 'featured',
        loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
      },
      {
        path: 'swyp-featured',
        loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule),
        canLoad: [OnlySwypGuard],
        canActivate: [OnlySwypGuard],
        canActivateChild: [OnlySwypGuard]
      },
      {
        path: 'network',
        loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule),
        canLoad: [OnlySwypGuard],
        canActivate: [OnlySwypGuard],
        canActivateChild: [OnlySwypGuard]
      },
      {
        path: 'redemption-history',
        loadChildren: () => import("./pages/redemption/redemption.module").then(m => m.RedemptionModule)
      },
      {
        path: 'savings-history',
        loadChildren: () => import("./pages/redemption/redemption.module").then(m => m.RedemptionModule)
      },
      {
        path: "",
        loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
      },
      {
        path: 'info',
        loadChildren: () => import("./pages/info/info.module").then(m => m.InfoModule)
      },
      {
        path: 'bonus-rewards',
        loadChildren: () => import("./pages/template4/perks-gifts/perks-gifts.module").then(m => m.PerksGiftsModule)
      },
      {
        path: "404",
        loadChildren: () => import("./pages/four-zero-four/four-zero-four.module").then(m => m.FourZeroFourModule)
      },
      {
        path: "giftcards",
        loadChildren: () => import("./pages/gift-cards/gift-cards.module").then(m => m.GiftCardsModule)
      },
      {
        path: "offers-on-giftcards",
        loadChildren: () => import("./pages/gift-cards/gift-cards.module").then(m => m.GiftCardsModule)
      },
      {
        path: "booking-failed",
        loadChildren: () => import("./pages/template4/booking-failed/booking-failed.module").then(m => m.BookingFailedModule)
      },
      {
        path: "charitable-donations",
        loadChildren: () => import("./pages/template4/karma-points/karma-points.module").then(m => m.KarmaPointsModule)
      },
      {
        path: "get_voucher",
        loadChildren: () => import("./pages/template4/redeem-gift-card/redeem-gift-card.module").then(m => m.RedeemGiftCardModule)
      },
      {
        path: "dining",
        loadChildren: () => import("./pages/template4/dining/dining.module").then(m => m.DiningModule)
      },
      {
        path: "memberships",
        loadChildren: () => import("./pages/template4/memberships/memberships.module").then(m => m.MembershipsModule)
      },
      {
        path: 'gift-card',
        loadChildren: () => import("./pages/perks-giftcards/perks-giftcards.module").then(m => m.PerksGiftCardsModule)
      },
      {
        path: 'reward',
        loadChildren: () => import("./pages/perks-giftcards/perks-giftcards.module").then(m => m.PerksGiftCardsModule)
      },
      {
        path: 'pre-checkout',
        loadChildren: () => import("./pages/pre-checkout/pre-checkout.module").then(m => m.PreCheckoutModule)
      },
      {
        path: 'membership',
        loadChildren: () => import("./pages/memberships/memberships.module").then(m => m.MembershipsModule)
      },
      {
        path: 'pre-checkout/:bundle_id',
        loadChildren: () => import("./pages/pre-checkout/pre-checkout.module").then(m => m.PreCheckoutModule)
      },
      { path: 'campaigns/:campaign_id', loadChildren: () => import('./pages/campaigns/campaigns.module').then(m => m.CampaignsModule) },
      { 
        // [2023-31-07] todo: add generic routing module for this
        path: 'campaigns/exclusive-rewards/:campaign_id', loadChildren: () => import('./pages/vistara-benefit/vistara-benefit.module').then(m => m.VistaraBenefitModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    useHash: false
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
