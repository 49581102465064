import { createReducer, on } from '@ngrx/store';
import * as countryActions from '../actions/countries.action';

export interface CountriesState {
  countries: any;
}
export const initialState: any = {};

export const countriesReducer = createReducer(
  initialState,

  on(countryActions.countriesSuccess, (state, result) => ({countries: result}))
  // on(countryActions.countriesSuccess, (state, {result}) => [result])
  // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
);
