<app-template4-header *ngIf="GlobalConstants.data.header_type == 'idfc'" class="small-header"></app-template4-header>
<section class="main-container mar-top-40">
    <div class="flex align-center justify-center h100 loader-parent" *ngIf="is_loading">
        <div class="page-loader"></div>
    </div>
    <section *ngIf="!is_loading">
        <section class="congrats-bar text-center pad-top-40 pad-bot-30 c0E202E fnt-20">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path
                        d="M24.5001 49.0001C38.0311 49.0001 49.0001 38.0311 49.0001 24.5001C49.0001 10.969 38.0311 0 24.5001 0C10.969 0 0 10.969 0 24.5001C0 38.0311 10.969 49.0001 24.5001 49.0001Z"
                        fill="#FFD949" />
                    <path
                        d="M39.6151 24.5163C39.4774 32.8598 32.8416 39.4948 24.4981 39.6326C16.1543 39.7703 9.51607 32.6517 9.38176 24.5163C9.36129 23.2702 7.42696 23.2682 7.44743 24.5163C7.60251 33.9166 15.0976 41.4117 24.498 41.5668C33.8992 41.7219 41.3977 33.6833 41.5494 24.5163C41.5699 23.2682 39.6356 23.2702 39.6151 24.5163Z"
                        fill="#333333" />
                    <path
                        d="M17.0529 19.8968C19.1779 19.8968 20.9006 18.1741 20.9006 16.049C20.9006 13.9239 19.1779 12.2012 17.0529 12.2012C14.9278 12.2012 13.2051 13.9239 13.2051 16.049C13.2051 18.1741 14.9278 19.8968 17.0529 19.8968Z"
                        fill="#333333" />
                    <path
                        d="M31.6027 19.8968C33.7277 19.8968 35.4505 18.1741 35.4505 16.049C35.4505 13.9239 33.7277 12.2012 31.6027 12.2012C29.4776 12.2012 27.7549 13.9239 27.7549 16.049C27.7549 18.1741 29.4776 19.8968 31.6027 19.8968Z"
                        fill="#333333" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="49" height="49" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <div class="pad-top-20 pad-left-35 pad-right-35 congrats-line"
                *ngIf="orderStatus == 'initiated' || orderStatus == 'partial_success'">Thank you! Your order was
                partially successful. You can now redeem your Gift Cards</div>
            <div class="pad-top-20 pad-left-35 pad-right-35 congrats-line" *ngIf="orderStatus == 'success'">Thank you
                for your order! You can now redeem your Gift Cards</div>
            <div class="pad-top-20 pad-left-35 pad-right-35 congrats-line" *ngIf="orderStatus == 'failure'">Sorry your
                order has failed!</div>
        </section>

        <section class="info-sec pad-30 mar-top-35 pad-bot-1">
            <div class="review-row mar-bot-30" *ngFor="let gift of giftcards; let i = index">
                <div class=" flex mar-bot-25">
                    <!-- loop< -->
                    <div class="review-img"><img [defaultImage]="'https://images.poshvine.com/b/default-giftcard.png'"
                            [lazyLoad]="gift.offer_image" class="img-fluid">
                    </div>
                    <div class="review-info c2E2E2E f500 flex pad-left-10">
                        <div>
                            <div>{{gift.merchant_name}}</div>
                            <div class="flex">
                                <div>
                                    <div class="redeem-detail flex">
                                        <svg class="mar-right-7 mar-top-4" width="10" height="10" viewBox="0 0 10 10"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5" r="5" fill="#EFF0F2" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.44379 6.31611L3.32683 5.19914L2.94647 5.57682L4.44379 7.07414L7.65808 3.85986L7.2804 3.48218L4.44379 6.31611Z"
                                                fill="#323937" />
                                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="3"
                                                width="6" height="5">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4.44379 6.31611L3.32683 5.19914L2.94647 5.57682L4.44379 7.07414L7.65808 3.85986L7.2804 3.48218L4.44379 6.31611Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0)">
                                            </g>
                                        </svg>
                                        <div>
                                            <div class="redeem-result fnt-12 c333 ">Denomination</div>
                                            <div class="denomination-amt f600 pad-top-2 fnt-13"><span
                                                    class="rupee-symbol">₹</span> {{toLocal(gift.denom)}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pad-left-18 jlf">
                                    <div class="redeem-detail flex">
                                        <svg class="mar-right-7 mar-top-4" width="10" height="10" viewBox="0 0 10 10"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5" r="5" fill="#EFF0F2" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.44379 6.31611L3.32683 5.19914L2.94647 5.57682L4.44379 7.07414L7.65808 3.85986L7.2804 3.48218L4.44379 6.31611Z"
                                                fill="#323937" />
                                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="3"
                                                width="6" height="5">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4.44379 6.31611L3.32683 5.19914L2.94647 5.57682L4.44379 7.07414L7.65808 3.85986L7.2804 3.48218L4.44379 6.31611Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0)">
                                            </g>
                                        </svg>
                                        <div>
                                            <div class="redeem-result fnt-12 c333">Quantity</div>
                                            <div class="denomination-amt f600 pad-top-2 fnt-13">{{gift.quantity}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pad-left-18 jlf total-box">
                                    <div class="redeem-detail flex">
                                        <svg class="mar-right-7 mar-top-4" width="10" height="10" viewBox="0 0 10 10"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5" r="5" fill="#EFF0F2" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.44379 6.31611L3.32683 5.19914L2.94647 5.57682L4.44379 7.07414L7.65808 3.85986L7.2804 3.48218L4.44379 6.31611Z"
                                                fill="#323937" />
                                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="3"
                                                width="6" height="5">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4.44379 6.31611L3.32683 5.19914L2.94647 5.57682L4.44379 7.07414L7.65808 3.85986L7.2804 3.48218L4.44379 6.31611Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0)">
                                            </g>
                                        </svg>
                                        <div>
                                            <div class="redeem-result fnt-12 c333">Total Value</div>
                                            <div class="denomination-amt f600 pad-top-2 fnt-13"><span
                                                    class="rupee-symbol">₹</span> {{toLocal(gift.total)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voucher-box flex mar-bot-15" *ngFor="let voucher of gift.vouchers">
                    <div class="code-box flex space-between align-center fnt-13 relative"
                        [ngStyle]="{'border-color': GlobalConstants.data.colors.mainBtn_color}">
                        <div class="absolute gv-lbl fnt-10 pad-left-3 pad-right-3">GV CODE</div>
                        <span>{{voucher.card_number}}</span> <svg class="pointer" width="14" height="16"
                            viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                            (click)="copy(voucher.card_number)" class="copy-icon pointer">
                            <path [ngStyle]="{'fill': GlobalConstants.data.colors.mainBtn_color}"
                                d="M10.3158 0H1.47368C0.663158 0 0 0.654545 0 1.45455V11.6364H1.47368V1.45455H10.3158V0ZM9.57895 2.90909H4.42105C3.61053 2.90909 2.95474 3.56364 2.95474 4.36364L2.94737 14.5455C2.94737 15.3455 3.60316 16 4.41368 16H12.5263C13.3368 16 14 15.3455 14 14.5455V7.27273L9.57895 2.90909ZM4.42105 14.5455V4.36364H8.8421V8H12.5263V14.5455H4.42105Z" />
                        </svg>
                    </div>
                    <div *ngIf="voucher.pin && voucher.pin != ''"
                        class="code-box pin-box mar-left-20 flex space-between align-center fnt-13 relative"
                        [ngStyle]="{'border-color': GlobalConstants.data.colors.mainBtn_color}">
                        <div class="absolute gv-lbl fnt-10 pad-left-3 pad-right-3">
                            GV PIN</div>
                        <span class="pad-right-20 pin-code">{{voucher.pin}}</span>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                            (click)="copy(voucher.pin)" class="copy-icon pointer">
                            <path [ngStyle]="{'fill': GlobalConstants.data.colors.mainBtn_color}"
                                d="M10.3158 0H1.47368C0.663158 0 0 0.654545 0 1.45455V11.6364H1.47368V1.45455H10.3158V0ZM9.57895 2.90909H4.42105C3.61053 2.90909 2.95474 3.56364 2.95474 4.36364L2.94737 14.5455C2.94737 15.3455 3.60316 16 4.41368 16H12.5263C13.3368 16 14 15.3455 14 14.5455V7.27273L9.57895 2.90909ZM4.42105 14.5455V4.36364H8.8421V8H12.5263V14.5455H4.42105Z" />
                        </svg>
                    </div>
                    <a href="{{gift?.vouchers?.merchant?.website}}" target="_blank"
                        class="resend-b f500 btn fnt-13 mar-left-25 redeem-btn"
                        [style.background-color]="GlobalConstants.data.colors.mainBtn_color">Redeem</a>
                </div>
                <div *ngIf="orderStatus == 'success' || orderStatus == 'initiated'">
                    <div class="mar-top-20 validity-box c54565B fnt-11 pad-bot-6 mar-bot-9"
                        *ngIf="gift.vouchers.length > 0">Valid till : <span class="f500">{{gift.vouchers[0]?.validity |
                            date: 'mediumDate'}}</span></div>
                    <div class="note-box c54565B fnt-12 ln-21">Please use your Gift Card Code at checkout in merchant app/site. In case of in-store usage, present the Gift Card during billing to redeem.</div>
                </div>
            </div>
        </section>
    </section>
</section>
<!-- <app-footer></app-footer> -->
