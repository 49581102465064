import { createAction, props } from '@ngrx/store';

export const getOffers = createAction('[Offers] Get Offers', props<any>());
export const offersFailure = createAction('[Offers] Offers failed', props<any>());
export const offersSuccess = createAction('[Offers] Offers sucess', props<any>());

export const getCampaignBasedOffers = createAction('[Campaign Offers] Get Campaign Offers', props<any>());
export const campaignOffersFailure = createAction('[Campaign Offers] Campaign Offers failed', props<any>());
export const campaignOffersSuccess = createAction('[Campaign Offers] Campaign Offers sucess', props<any>());

export const getMerchants = createAction('[Merchant Offers] Get Merchant Offers', props<any>());
export const merchantOffersFailure = createAction('[Merchant Offers] Merchant Offers failed', props<any>());
export const merchantOffersSuccess = createAction('[Merchant Offers] Merchant Offers sucess', props<any>());
