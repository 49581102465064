import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataChangeService {
  private data = new BehaviorSubject('/-');
  data$ = this.data.asObservable();

  private offerToRedeem = new BehaviorSubject(null);
  offerToRedeem$ = this.offerToRedeem.asObservable();

  changeData(data: any) {
    this.data.next(data)
  }

  changeOfferToRedeem(offer: any) {
    console.log(offer)
    this.offerToRedeem.next(offer);
  }

  getOfferValue() {
    console.log(this.offerToRedeem, this.offerToRedeem.value, this.offerToRedeem.getValue())
    return this.offerToRedeem.value;
  }
}
