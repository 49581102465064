import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from 'src/app/pages/common/api.service';
import * as countryActions from '../actions/countries.action';

@Injectable()

export class CountriesEffects{
  constructor(
    private actions$: Actions,
    private ApiService: ApiService
  ){}

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countryActions.getCountries),
      exhaustMap(() =>
        this.ApiService.getAllCountries().pipe(
          map(response => countryActions.countriesSuccess(response)),
          catchError((error: any) => of(countryActions.countriesFailure(error))))
      )
    ),{dispatch: true}
  );
}
