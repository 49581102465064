import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from './global-variables';
@Injectable({
  providedIn: "root"
})
export class ApiService {
	beta = false;
	constructor(private http: HttpClient, public GlobalConstants: GlobalConstants) {
		environment.corePath = environment.corePathV2;
		environment.offerPath = environment.offerPathV2;
	}
	reloadTemplate = new EventEmitter<any>();
	getUser(userId) {
		return this.http.get<any>(environment.corePath + '/users/' + userId, {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}
	getUserDetails() {
		return this.http.get<any>(environment.corePath + '/user', {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}

	getAllCountries() {
		return this.http.get(environment.corePath + "/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getApplicableCountries() {
		return this.http.get(environment.offerPath + "/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllInternationalCountries() {
		return this.http.get(environment.offerPath + "/international_offers/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllCities(params) {
		return this.http.get(environment.corePath + "/cities", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getApplicableCities(params?) {
		return this.http.get(environment.offerPath + "/nearby_offers/states", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllInternationalCities(params) {
		return this.http.get(environment.offerPath + "/international_offers/cities", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllLocalities(params) {
		return this.http.get(environment.corePath + "/localities", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getApplicableLocalities(params) {
		return this.http.get(environment.offerPath + `/nearby_offers/localities`, {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getIpStackLocation() {
    return this.http.get(`https://api.ipstack.com/check?access_key=${environment?.ipstackKey}&format=1`).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
  addLocation(params) {
    return this.http.post<any>(environment.corePath + "/user/user_locations/log", params).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
	getCards(params = {}) {
		return this.http.get<any>(environment.corePath + '/user/user_cards', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => of(error))
			);
	}
	fetchCards(params = {}) {
		return this.http.get<any>(environment.corePath + '/user/user_cards/fetch', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}

	getCardsWithPoints(params = {}) {
		return this.http.get<any>(environment.corePath + '/user_card', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}

	binValidation(bin) {
		return this.http.get<any>(environment.offerPath + "/bins/card_details?bin=" + bin).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
	cardValidation(bin) {
		return this.http.get<any>(environment.corePath + "/bins/validate?bin=" + bin).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
	addCard(bin, bin_type) {
		return this.http.post<any>(environment.corePath + "/user/user_cards", { bin: bin, bin_type: bin_type }).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	validateCard(bin, bin_type) {
		return this.http.get<any>(environment.corePath + "/user/user_cards/search?bin=" + bin + "&bin_type=" + bin_type).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	switchCard(card_id) {
		return this.http.post<any>(environment.corePath + `/user_card/switch`, {card_id: card_id}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getRedemptions(params = {}) {
		return this.http.get(environment.offerPath + "/redemptions?", { params: params }).pipe(
			map(res => res || []),
			catchError(error => of(error))
		);
	}
	getGiftRedemptions(params = {}) {
		return this.http.get(environment.offerPath + "/gift_card_bookings?", { params: params }).pipe(
			map(res => res || []),
			catchError(error => of(error))
		);
	}

	resendGift(id) {
		return this.http.get(environment.corePath + "/gift_cards/resend_gift_card?gift_card_order_id=" + id).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getClient(v2 = false) {
		var cpath = v2 == false ? environment.corePath : environment.corePathV2;
		return this.http.get(cpath + "/client").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getOffers(params, isNearBy?, isInternational?) {
		if (isNearBy) return this.getNearbyOffers(params);
		if (isInternational) return this.getInternationalOffers(params);
		return this.http.get(environment.offerPath + "/offers?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCampaignBasedOffers(campaignId, params) {
		return this.http.get(environment.campaignPath + "/campaigns/"+campaignId+"/offer_redemption_options", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getGiftCards(id, params = {}) {
		return this.http.get(environment.offerPath + "/gift_cards/" + id + "?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getMemberships(id, params = {}) {
		return this.http.get(environment.offerPath + "/memberships/" + id + "?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCategories(params) {
		return this.http.get(environment.offerPath + "/categories?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCategoryDetails(params = {}) {
		return this.http.get(environment.offerPath + "/category_details?elgble=true", {
			params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCategoryDetail(id, params) {
		return this.http.get(environment.offerPath + "/category_details/" + id + "?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	searchMerchants(params) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPath + "/merchants/search", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getMerchants(params) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPath + "/merchants?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getNearByMerchants(params) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPath + "/nearby_offers/merchants?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getInternationalMerchants(params) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPath + "/international_offers/merchants?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getNearByCategories(params?: any) {
		let data = params || {};
		// params['per_page'] = 30;
		return this.http.get(environment.offerPath + "/nearby_offers/categories?elgble=true", {
			params: data
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getInternationalCategories(params?: any) {
		let data = params || {};
		// params['per_page'] = 30;
		return this.http.get(environment.offerPath + "/international_offers/categories?elgble=true", {
			params: data
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getNearbyOffers(params) {
		// params['per_page'] = 30;
		return this.http.get(environment.offerPath + "/nearby_offers?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getInternationalOffers(params) {
		// params['per_page'] = 30;
		return this.http.get(environment.offerPath + "/international_offers?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getBundles() {
		return this.http.get(environment.offerPath + "/bundles?elgble=true").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getBundlesGroups() {
		return this.http.get(environment.offerPath + "/bundle_groups?elgble=true").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getClientBundles() {
		return this.http.get(environment.offerPath + "/bundles").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getBundle(id) {
		return this.http.get(environment.offerPath + "/bundle_groups/" + id + "?elgble=true").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getBundleMerchant(id) {
		return this.http.get(environment.offerPath + "/bundles/" + id).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	redeem(offer, params, exclude_project_id?) {
		if(exclude_project_id){
			const httpOptions = {
	      headers: new HttpHeaders({
	        'exclude_project_id': 'true'
	      })
	    }
			return this.http.post(environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true", params, httpOptions).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
		else{
			return this.http.post(environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true", params).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
	}

	resendVoucher(offer) {
		return this.http.get(environment.offerPath + "/offers/" + offer.id + "/resend_voucher", {}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	redeemByBin(offer, bin) {
		return this.http.post(environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true", { bin: bin }).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	createTokenUser(token, uid?, client?, attr = 'token') {
		let params = {};
		if (token) params[attr] = token;
		if (uid) params['uid'] = uid;
		if (client) params['client'] = client;
		if (this.GlobalConstants.data.api_version == 'v1') {
			return this.http.post(environment.corePath + "/sessions/create_with_user", params).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
		else {
			return this.http.post(environment.corePath + "/sessions", params).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
	}

	checkCardEligibility(bin, bin_type, bundle_id) {
		return this.http.get(environment.offerPath + "/bins/validate?bin=" + bin + "&bundle_id=" + bundle_id).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	//idfc Apis
	getNtbGiftcards() {
		return this.http.get(environment.offerPath + `/special_gift_cards`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getSpecialBundles() {
		return this.http.get(environment.offerPath + `/special_bundles?elgble=true`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getSpecialBundlesById(id) {
		return this.http.get(environment.offerPath + `/special_bundles/${id}`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getRewardBookings() {
		return this.http.get(environment.apiUrlBsV1 + 'bookings').pipe(
			map(res => res || []),
			catchError(error => of(error))
		);
	}
	getNtbGiftRedeem(bundleId, giftId) {
		return this.http.post(environment.offerPath + `/special_bundles/${bundleId}/redeem?item_id=${giftId}`, null).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getPaymentInfo(params) {
		return this.http.post(environment.apiUrlPaV1 + "payments/bookings_payment_info", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	cancelBooking(params) {
		return this.http.post(environment.apiUrlBsV1 + 'bookings/cancel_booking', params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getRestaurantCount() {
		return this.http.get(environment.apiUrlBsV1 + 'restaurants/restaurant_count').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getBanners(params) {
		return this.http.get(environment.offerPathV2 + "/client_banners?elgble=true", {params: params}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getTerms(id) {
		return this.http.get(environment.offerPathV2 + "/offers/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getGiftTerms(id) {
		return this.http.get(environment.offerPathV2 + "/gift_cards/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getMembershipTerms(id) {
		return this.http.get(environment.offerPathV2 + "/memberships/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	//giftcard cart
	getCredits() {
		return this.http.get(environment.offerPathV2 + `/credits/used`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getGiftcardsInCart() {
		return this.http.get(environment.offerPathV2 + `/cart/cart_items?elgble=true`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	deleteFromCart(deleteId) {
		return this.http.delete(environment.offerPathV2 + `/cart/cart_items/${deleteId}`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	pushToCart(cartParams, discounted_project_id = false) {
		console.log(discounted_project_id)
		let httpOptions = {}
		if(discounted_project_id){
				httpOptions = {
			  headers: new HttpHeaders({
				'discounted_project_id': 'true'
			  })
			}
		}
		return this.http.post(environment.offerPathV2 + "/cart/add_items", cartParams, httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);

	}
	redeemFromCart(params = null) {
		return this.http.post(environment.offerPath + "/gift_cards/redeem", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	checkPinCode(pin) {
		return this.http.get(`https://api.postalpincode.in/pincode/${pin}`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	checkCordinates(cordinates) {
		return this.http.get(`https://api.opencagedata.com/geocode/v1/json?q=${cordinates}&key=d73135d9826a49ef91a2bf334da1be37`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	updateUserCreds(params) {
		return this.http.put(environment.corePathV2 + "/user", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	reseemMembership(cartParams) {
		return this.http.post(environment.offerPath + `/special_bundles/${cartParams.bundle_id}/redeem_membership?item_id=${cartParams.membership_id}`, cartParams).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCampaign(campaignId){
		return this.http.get(environment.campaignEnterprisePath + `campaigns/${campaignId}`, {}).pipe(
		  map((res) => res || []),
		  catchError((error) => throwError(error))
		);
	}

	getCampaignInappCommunicationSettings(campaignId, triggerId){
		return this.http.get(environment.campaignEnterprisePath + `campaigns/`+campaignId+`/trigger_settings/`+triggerId+`/in_app_communication_settings`, {}).pipe(
		  map((res) => res || []),
		  catchError((error) => throwError(error))
		);
	}

	getCampaignOffers(campaignId) {
		let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		return this.http.get(environment.campaignPath + `campaigns/`+campaignId+`/offer_redemption_options`).pipe(
			map(res => res || []),
			catchError(error => of(error))
		);
	}

	getCampaignMemberships(campaignId) {
		let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		return this.http.get(environment.campaignPath + `campaigns/`+campaignId+`/membership_redemption_options`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCampaignGiftcards(campaignId) {
		let currentUserCard = JSON.parse(localStorage.getItem('userCard'));
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));

		return this.http.get(environment.campaignPath + `campaigns/`+campaignId+`/gift_card_redemption_options`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	redeemCampaignOffer(campaignId, offerId, formData) {
		let params = new HttpParams({
			fromObject: formData,
		});
		let httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
		};
		return this.http.post(environment.campaignPath + `/campaigns/`+campaignId + `/offer_redemption_options/` + offerId + `/redeem`, params.toString(), httpOptions).pipe(
		map((res) => res || []),
		catchError((error) => throwError(error))
		);
	}

	redeemCampaignGiftcard(campaignId, giftcardId, formData) {
		let params = new HttpParams({
			fromObject: formData,
		});
		let httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
		};
		return this.http.post(environment.campaignPath + `/campaigns/`+campaignId + `/gift_card_redemption_options/` + giftcardId + `/redeem`, params.toString(), httpOptions).pipe(
		map((res) => res || []),
		catchError((error) => throwError(error))
		);
	}

	redeemCampaignMembership(campaignId, membershipId, formData) {
		let params = new HttpParams({
			fromObject: formData,
		});
		let httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
		};
		return this.http.post(environment.campaignPath + `/campaigns/`+campaignId + `/membership_redemption_options/` + membershipId + `/redeem`, params.toString(), httpOptions).pipe(
		map((res) => res || []),
		catchError((error) => throwError(error))
		);
	}

	getCampaignBanners() {
		return this.http.get(environment.campaignPath + "campaigns/banners").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCampaigns(params) {
		return this.http.get(environment.campaignPath + "/campaigns", params).pipe(
			map(res => res || []),
			catchError(error => of(error))
		);
	}
	getCampaignRedemptions(params) {
		return this.http.get(environment.campaignPath + "/user_redemptions", params).pipe(
			map(res => res || []),
			catchError(error => of(error))
		);
	}

}
