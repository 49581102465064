import { Component, OnInit, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { ApiService } from '../service/gift.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-shared-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {


  is_loading = true;

  constructor(
    private http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    private _apiService: ApiService,
    public GlobalConstants: GlobalConstants,
    private toastr: ToastrService,
    public meta: Meta,
    public pageTitle: Title,
  ) { }

  ngOnInit() {
    this.seo();
    this.route.params.subscribe(
      params => {

        this.getOrderData(params.order_id)

      });
  }
  ngOnChanges(change) {


  }
  copy(code) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied!');
  }
  toLocal(num) {
    return Number(num).toLocaleString('en-IN')
  }
  seo() {

    var title = 'Order Review' + ' - ' + this.GlobalConstants.data.titles.homepage_pagetitle;
    this.pageTitle.setTitle(title);
    this.meta.addTags([
      { name: 'description', content: this.GlobalConstants.data.titles.homepage_description },
      { name: 'keywords', content: this.GlobalConstants.data.titles.homepage_keywords }
    ]);
  }
  orderStatus: any;
  giftcards = [];
  getOrderData(id) {
    this.is_loading = true;
    this._apiService.getOrderData(id).subscribe(
      (res: any) => {
        res.data.attributes.gift_card_orders.forEach((gift, index) => {
          this.orderStatus = res.data.attributes.status;
          let singlecard = {
            'denom': gift.denomination,
            'merchant_name': gift.merchant.name,
            'quantity': gift.quantity,
            'total': parseInt(gift.quantity) * parseInt(gift.denomination),
            'status': gift.status,
            'merchant_image': gift.merchant.logo,
            'offer_image': gift.gift_card_image,
            'vouchers': gift.vouchers ? gift.vouchers : [],
            'redemption_type': gift.redemption_type ? gift.redemption_type : [],
            'id': gift.gift_card_id || gift.merchant.id

          };
          this.giftcards.push(singlecard)
        });


        this.is_loading = false;
      },
      (err) => {
      }
    );
  }

}
