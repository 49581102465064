import { CartComponent as GiftCardCartComponent} from './../gift-cards/cart/cart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart/cart.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ApiService } from './service/gift.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { FooterModule } from 'src/app/layout/footer/footer.module';
import { Template4HeaderModule } from '../template4/header/header.module';
import { HeaderModule } from 'src/app/layout/header/header.module';
import { RouterModule } from '@angular/router';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { OffersCardComponent } from './offers-card/offers-card.component';
import { MilestoneCardComponent } from './milestone-card/milestone-card.component';
import { SharedModule as Template4SharedModule } from 'src/app/pages/template4/shared/shared.module';
import { CouponModalComponent } from './coupon-modal/coupon-modal.component';
// import { ClipboardModule } from 'ngx-clipboard';
import { NoDataComponent } from './no-data/no-data.component';
import { FilterPipe } from './pipes/filter.pipe';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GiftMemberMobComponent } from './gift-member-mob/gift-member-mob.component';
import { OfferListingComponentV2 } from "./offer-listing-v2/offer-listing-v2.component";
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { TabsCartComponent } from './tabs-cart/tabs-cart.component';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfSureComponent } from 'src/app/pages/common/if-sure/if-sure.component';

import { SliderComponent } from './slider/slider.component';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { ChooseOffersComponent } from './choose-offers/choose-offers.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChooseMembershipsComponent } from './choose-memberships/choose-memberships.component';

const routes = [
  { path: "activation-checkout/:order_id", component: CheckoutComponent }
];

@NgModule({
  declarations: [
    CartComponent,
    GiftCardCartComponent,
    CheckoutComponent,
    ConfirmModalComponent,
    OffersCardComponent,
    MilestoneCardComponent,
    CouponModalComponent,
    NoDataComponent,
    FilterPipe,
    EllipsisPipe,
    TermsModalComponent,
    GiftMemberMobComponent,
    OfferListingComponentV2,
    TabsCartComponent,
    IfSureComponent,
    SliderComponent,
    AutofocusDirective,
    ChooseOffersComponent,
    ChooseMembershipsComponent,
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    FooterModule,
    Template4HeaderModule,
    HeaderModule,
    RouterModule.forChild(routes),
    Template4SharedModule,
    // ClipboardModule,
    PopoverModule.forRoot(),
    MatDialogModule,
    InfiniteScrollModule
  ],  
  providers: [ApiService, { provide: MAT_DIALOG_DATA, useValue: {} }],
  exports: [
    CartComponent,
    GiftCardCartComponent,
    OfferListingComponentV2,
    ConfirmModalComponent,
    MilestoneCardComponent,
    OffersCardComponent,
    CouponModalComponent,
    NoDataComponent,
    FilterPipe,
    EllipsisPipe,
    TermsModalComponent,
    GiftMemberMobComponent,
    TabsCartComponent,
    Template4SharedModule,
    SliderComponent,
  ]
})
export class SharedModule { }
