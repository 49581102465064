import { createReducer, on } from '@ngrx/store';
import * as offerActions from '../actions/offers.action';

export interface OffersState {
  offers: any;
  campaignBasedOffers: any;
}
export const initialState: any = {};

export const offersReducer = createReducer(
  initialState,
  on(offerActions.offersSuccess, (state, result) => ({offers: result})),
  // on(bannerActions.bannersFailure, (state, {result}) => [result])
  // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
);
// 
//
// export const categoriesDetailsReducer = createReducer(
//   initialState,
//   on(offerActions.categoryDetailsSuccess, (state, result) => ({categoryDetails: result})),
//   // on(bannerActions.bannersFailure, (state, {result}) => [result])
//   // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
// );
