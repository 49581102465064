import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from 'src/app/pages/common/api.service';
import * as categoryActions from '../actions/categories.action';

@Injectable()

export class CategoriesEffects{
  constructor(
    private actions$: Actions,
    private ApiService: ApiService
  ){}

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.getCategories),
      // exhaustMap((a) =>
      switchMap((action) =>
        this.ApiService.getCategories(action.params).pipe(
          map(response => categoryActions.categoriesSuccess(response)),
          catchError((error: any) => of(categoryActions.categoriesFailure(error))))
      )
    ),{dispatch: true}
  );

  loadCategoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.getCategoryDetails),
      // exhaustMap((a) =>
      switchMap((action) =>
        this.ApiService.getCategoryDetails(action.params).pipe(
          map(response => categoryActions.categoryDetailsSuccess(response)),
          catchError((error: any) => of(categoryActions.categoryDetailsFailure(error))))
      )
    ),{dispatch: true}
  );
}
