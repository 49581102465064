import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { HeaderComponent } from "./header.component";
import {FormsModule,ReactiveFormsModule } from "@angular/forms";
import { ClickOutsideModule } from 'ng-click-outside';
import { AuthenticationModule } from '../../pages/common/authentication/authentication.module';
import { SearchComponent } from './search-modal/search-modal.component';
import { SearchBasicComponent } from './search-basic/search-basic.component';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';

@NgModule({
  declarations: [HeaderComponent,SearchComponent, SearchBasicComponent, CountryDropdownComponent],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AuthenticationModule

  ],
  exports: [ HeaderComponent, SearchComponent,SearchBasicComponent, CountryDropdownComponent],
})
export class HeaderModule { }
