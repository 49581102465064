import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
@Injectable()
export class ApiService {

	constructor(private http: HttpClient, public GlobalConstants: GlobalConstants) {
		if (this.GlobalConstants.data.api_version == 'v2') {
			environment.corePath = environment.corePathV2;
			environment.offerPath = environment.offerPathV2;
		}
	}



	//idfc Apis
	getNtbGiftcards() {
		return this.http.get(environment.offerPath + `/special_gift_cards`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getSpecialBundles() {
		return this.http.get(environment.offerPath + `/special_bundles`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getSpecialBundlesById(id) {
		return this.http.get(environment.offerPath + `/special_bundles/${id}?elgble=true`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
  getNtbGiftRedeem(bundleId, giftId) {
		return this.http.post(environment.offerPath + `/special_bundles/${bundleId}/redeem?item_id=${giftId}`, null).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	//giftcard cart
	getGiftcardsInCart(params) {
		return this.http.get(environment.offerPath + `/cart`, {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }
	getOrderData(id) {
		return this.http.get(environment.offerPath + `/gift_card_bookings/${id}`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }
	deleteFromCart(cartParams) {
		return this.http.post(environment.offerPath + "/cart/add_items", cartParams).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }
	pushToCart(cartParams, discounted_project_id=false) {
		console.log(discounted_project_id)
		let httpOptions = {}
		if(discounted_project_id){
				httpOptions = {
			  headers: new HttpHeaders({
				'discounted_project_id': 'true'
			  })
			}
		}
		return this.http.post(environment.offerPath + "/cart/add_items", cartParams, httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);

  }
	redeemSpecialBundle(params) {
		return this.http.post(environment.offerPath + "/special_bundles/redeem", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);

  }
}
