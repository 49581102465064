import { AuthenticationService } from "./../../common/authentication.service";
import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalConstants } from "../../common/global-variables";

enum Tab {
  Home = 1,
  Gifts,
  Offers,
  Dining,
  Charity,
}

@Component({
  selector: "app-template4-footer",
  templateUrl: "./template4-footer.component.html",
  styleUrls: ["./template4-footer.component.scss"],
})
export class Template4FooterComponent implements OnInit, AfterViewChecked {
  tabs = Tab;
  selectedTab: Tab = Tab.Home;
  isUserAuthenticated = false;
  isFirstPrivate = this.authService.isFirstPrivateCard;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public GlobalConstants: GlobalConstants
  ) {}

  ngOnInit(): void {
    this.checkIfAuthenticated();
  }

  ngAfterViewChecked(): void {
    this.checkUrl();
  }

  switchTab(ev): void {
    this.selectedTab = ev;
    let url: string;
    switch (ev) {
      case this.tabs.Home: {
        url = "";
        break;
      }
      case this.tabs.Gifts: {
        url = "/memberships";
        break;
      }
      case this.tabs.Offers: {
        url = "/offers";
        break;
      }
      case this.tabs.Dining: {
        url = "/dining";
        break;
      }
      case this.tabs.Charity: {
        url = "/charitable-donations";
        break;
      }
      default: {
        url = "";
        break;
      }
    }
    this.router.navigate([url]);
  }

  checkUrl() {
    const url = this.router.url;
    if (url.includes("/dining")) {
      this.selectedTab = this.tabs.Dining;
    } else if (url.includes("/offers")) {
      this.selectedTab = this.tabs.Offers;
    } else if (url.includes("/giftcards") || url.includes("/memberships")) {
      this.selectedTab = this.tabs.Gifts;
    } else if (url.includes("/charitable-donations")) {
      this.selectedTab = this.tabs.Charity;
    } else {
      this.selectedTab = this.tabs.Home;
    }
  }

  checkIfAuthenticated() {
    this.authService.currentUserSubject.subscribe((res) => {
      this.isUserAuthenticated = res ? true : false;
    });
  }
}
