import { Component, OnInit, Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";

interface OffersSection {
  featuredOffers: string;
  swypFeaturedOffers: string;
  networkOffers: string;
};

@Injectable({
  providedIn: "root",
})
export class GlobalConstants {
  client: any;
  data: any;

  redemptionFilters = {
    OFFERS: "offers",
    SPECIAL: "special",
    ACTIVATION: "activation",
    REWARDS_BOOKING: {
      GIFT_CARD: "gift_card",
      MEMBERSHIP: "membership",
      DINING: "dining",
      CHARITY: "charity",
    },
  };

  public offersContext: OffersSection = {
    featuredOffers: 'featured',
    swypFeaturedOffers: 'card-offers-swyp',
    networkOffers: 'network',
  }

  public offersRoute: OffersSection = {
    featuredOffers: 'featured',
    swypFeaturedOffers: 'swyp-featured',
    networkOffers: 'network',
  }

  public sectionTitle: OffersSection = {
    featuredOffers: 'Top Offers',
    swypFeaturedOffers: 'FIRST SWYP Featured Partners',
    networkOffers: 'Offers for You',
  };

  public mapCardNameToPath: Record<string, (unknown) => string> = {
    "IDFC Vistara Mastercard World Credit Card": (vars) => `/campaigns/exclusive-rewards/${vars.campaignId}`
  };

  constructor(@Inject(DOCUMENT) private document: any, private router: Router) {
    var subDomain = this.document.location.hostname
      .split("www.")
      .join("")
      .split(".", 1);
    subDomain = subDomain.join("").split("sandbox-").join("").split(".", 1);
    if (this.document.location.hostname.includes("poshvine")) {
      this.client = subDomain[0];
      if (this.client.includes("-development")) {
        this.client = this.client.split("-development").join("");
      }
    }
    if (
      this.document.location.hostname.includes("idfc") &&
      this.document.location.hostname.includes("gi")
    ) {
      this.client = "idfc-bank-gi";
    } else if (
      this.document.location.hostname.includes("idfc") &&
      !this.document.location.hostname.includes("gi")
    ) {

      this.client = "idfc-bank";
    }
    switch (this.client) {
      case "idfc-bank":
        this.data = {
          cards_disabled_for_points: ['IDFC Vistara Mastercard World Credit Card', 'IDFC Digital RuPay Select Credit Card'],
          logo: {
            url: "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            footerurl:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            desktop_url:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            modal_url:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            favicon: "./assets/favicon/idfc.ico",
            head_logo_height: "40",
            coupon_modal_logo:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
          },
          category_icons:{
              travel: 'https://images.poshvine.com/icon/travel-credit.jpg'
          },
          category_names:{
            travel: 'Travelverse'
          },
          assets: {
            img: {},
            manifest: "manifest.webmanifest",
          },
          links: {
            projectLink: "https://idfcfirstrewards.poshvine.com",
          },
          colors: {
            header_bg_color: "#fff",
            coupon_modal_bg_color: "#fff",
            offer_title_color: "#9D1D27",
            main_text_color: "#9D1D27",
            medium_text_color: "#54565B",
            light_text_color: "#9D9D9D",
            main_bg_color: "#036",
            active_card_bg_color: "#F4F4F4",
            seconday_bg_color: "#FDFCFA",
            mainBtn_color: "#9D1D27",
            inactive_btn_color: "#D8D8D8",
            btn_positive_action_color: "#06B08A",
            active_text_color: "#9D1D27",
          },
          legal_name: "IDFC FIRST Bank",
          font_family: {
            link: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap",
            name: "Inter",
          },
          x_header: {
            prod: {
              client_id: "3388bbd3-7e9b-4575-84f3-a0e53a6c4ace",
              project_id_credit: "d00ef9d9-da1b-49eb-b174-008cce00bacf",
              project_id_debit: null,
              discounted_project_id: "c99319af-4b39-44d3-a166-3bcc413050dd",
            },
            sandbox: {
              client_id: "9d15f989-200a-4c46-b8d8-5cad8bf14749",
              project_id_credit: "1e355181-47d4-4d80-9018-379199ddb7cc",
              project_id_debit: null,
              discounted_project_id: "e4d3e52e-aed8-4f2e-887b-27a6b7bec247",
            },
            default_card_type: "credit",
          },
          search_type: 2,
          desktop_category_count: 7,
          carouselInterval: 9000,
          card_num_type: "first4_last4",
          card_type: "Debit",
          tabs_type: "category",
          tabs_type_mobile: "category",
          has_exclusive: false,
          banner_type: "none",
          login_button_needed: true,
          loginAt: "otp",
          login_theme: 1,
          login_steps: ["card", "mobile", "otp" ],
          auto_expiry: {
            time: "30",
            enabled: false,
          },
          template: 4,
          header_type: "idfc",
          show_saving_for_bundle_codes: {},
          download_app_link: {
            header: false,
            footer: false,
            android_link: null,
            apple_link: null,
          },
          login_modal_type: "desktop-prev-header",
          supported_devices: ["mobile"],
          benefits: ["giftcards"],
          banner_style: 2,
          api_version: "v2",
          token_attr: "token",
          personalized_config: {
            coupon_terms_link: true,
            switch_card: false,
            autocard_check: false,
            view_savings: true,
            external_otp: false,
            redemption_page_needed: true,
            redemption_terms_btn: false,
            saving_page: false,
            resend_allowed: true,
            add_new_card: false,
            footer_logo: true,
            force_auth: false,
            token_auth: false,
            desktop_search: true,
            mobile_search: true,
            auth: true,
            coupon_modal: true,
            coupon_show: true,
            amount_show: false,
            modal_redeem_button: true,
            mobile_home_back_button: true,
            show_card_used: false,
            allow_user_create: false,
            dedicated_banner: false,
            mobile_banner: false,
            authenticate_before_claim: true,
            display_bundle_category_title: true,
            disclaimer_popup: false,
            cancelable_auth: true,
            mobile_login: false,
            is_concierge: false,
            has_card: true,
            home_subcategory_dropdown: true,
            home_subcategory_bar: false,
            has_near_by: false,
            has_international: false,
            mobile_profile_dropdown: false,
            category_page: true,
            authenticate_bin: true,
            datalayer: false,
            has_add_card_text: false,
            single_step_login: false,
            skip_card_if_exist: false,
            custom_gtm_tags: false,
            show_terms_before_redeem: false,
            details_desktop_tabs: false,
            show_special_category: false,
            send_project_id: true,
            show_card_applicability: false,
            card_applicability_fetch: "local",
            show_card_select_on_auth: false,
            special_bundle: true,
            external_login_banner: false,
            send_as_gift: false,
            applicableTag: true,
            has_maps: true,
            new_gift_card: true,
            perks_membership: false,
            points_redemtions: true,
            redemption_filters: this.redemptionFilters,
            smart_country_dropdown: true,
          },
          titles: {
            homepage_pagetitle: "Offers & Privileges - IDFC FIRST Bank",
            homepage_description: "",
            homepage_keywords: "",
            header_title: "",
            modal_mobile_label:
              "Enter your mobile number registered with IDFC FIRST Bank",
            card_name: "IDFC FIRST Bank Credit Card",
            platform_title: "IDFC FIRST Bank Offers and Benefits",
            concierge_name: "IDFC FIRST Bank",
            login_modal_control: "Validate Your Mobile Number",
          },
          footer_additional_content:
            "<p>Disclaimer: IDFC FIRST Bank Offers & Privileges is a platform for communication of personalized offers extended by Merchants to IDFC FIRST Bank's Customers. IDFC FIRST Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. IDFC FIRST Bank is neither guaranteeing nor making any representation. IDFC FIRST Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
          content: {
            bin_error: {
              first4_last4:
                "Dear customer, please re-check the first 4 and last 4 card number you have entered",
              first6:
                "Dear customer, please re-check the first 6 digits of your card number you have entered",
            },
            bin_invalid: {
              first4_last4:
                "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
              first6: "Invalid Card",
            },
            preset_error: "Invalid Credentials!",
          },
          email: {
            display: "banker@idfcfirstbank.com",
            val: "banker@idfcfirstbank.com",
          },
          phone: {
            display: "1800-10-888",
            val: "180010888",
          },
          rtm_enabled: true,
          show_redemption_history_filters: true,
        };
        break;
      case "idfc-bank-gi":
        this.data = {
          logo: {
            url: "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            footerurl:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            desktop_url:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            modal_url:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
            favicon: "./assets/favicon/idfc.ico",
            head_logo_height: "40",
            coupon_modal_logo:
              "https://images.poshvine.com/logos/IDFC_First_Bank_logo.jpg",
          },
          assets: {
            img: {},
            manifest: "manifest.webmanifest",
          },
          links: {
            projectLink: "https://idfcfirstrewards.poshvine.com", //need to update this
          },
          colors: {
            header_bg_color: "#fff",
            coupon_modal_bg_color: "#fff",
            offer_title_color: "#9D1D27",
            main_text_color: "#9D1D27",
            medium_text_color: "#54565B",
            light_text_color: "#9D9D9D",
            main_bg_color: "#036",
            active_card_bg_color: "#F4F4F4",
            seconday_bg_color: "#FDFCFA",
            mainBtn_color: "#9D1D27",
            inactive_btn_color: "#D8D8D8",
            btn_positive_action_color: "#06B08A",
            active_text_color: "#9D1D27",
          },
          legal_name: "IDFC FIRST Bank",
          font_family: {
            link: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap",
            name: "Inter",
          },
          x_header: {
            prod: {
              client_id: "3388bbd3-7e9b-4575-84f3-a0e53a6c4ace",
              project_id_credit: "d00ef9d9-da1b-49eb-b174-008cce00bacf",
              project_id_debit: null,
              discounted_project_id: "c99319af-4b39-44d3-a166-3bcc413050dd",
            },
            sandbox: {
              client_id: "9d15f989-200a-4c46-b8d8-5cad8bf14749",
              project_id_credit: "1e355181-47d4-4d80-9018-379199ddb7cc",
              project_id_debit: "1293cad0-de49-4163-b96c-13faee9a30a5",
              discounted_project_id: "e4d3e52e-aed8-4f2e-887b-27a6b7bec247",
            },
            default_card_type: "debit",
          },
          search_type: 2,
          desktop_category_count: 7,
          carouselInterval: 9000,
          card_num_type: "first6",
          card_type: "Debit",
          tabs_type: "category",
          tabs_type_mobile: "category",
          has_exclusive: false,
          banner_type: "none",
          login_button_needed: true,
          loginAt: "otp",
          login_theme: 1,
          login_steps: ["card", "mobile", "otp"],
          auto_expiry: {
            time: "30",
            enabled: false,
          },
          template: 4,
          header_type: "idfc",
          show_saving_for_bundle_codes: {},
          download_app_link: {
            header: false,
            footer: false,
            android_link: null,
            apple_link: null,
          },
          login_modal_type: "desktop-prev-header",
          supported_devices: ["mobile"],
          benefits: ["giftcards"],
          banner_style: 2,
          api_version: "v2",
          token_attr: "token",
          personalized_config: {
            coupon_terms_link: true,
            switch_card: false,
            autocard_check: false,
            view_savings: true,
            external_otp: false,
            redemption_page_needed: true,
            redemption_terms_btn: false,
            saving_page: false,
            resend_allowed: true,
            add_new_card: false,
            footer_logo: true,
            force_auth: false,
            token_auth: false,
            desktop_search: true,
            mobile_search: true,
            auth: true,
            coupon_modal: true,
            coupon_show: true,
            amount_show: false,
            modal_redeem_button: true,
            mobile_home_back_button: true,
            show_card_used: false,
            allow_user_create: true,
            dedicated_banner: false,
            mobile_banner: false,
            authenticate_before_claim: true,
            display_bundle_category_title: true,
            disclaimer_popup: false,
            cancelable_auth: true,
            mobile_login: false,
            is_concierge: false,
            has_card: true,
            home_subcategory_dropdown: true,
            home_subcategory_bar: false,
            has_near_by: false,
            has_international: false,
            mobile_profile_dropdown: false,
            category_page: true,
            authenticate_bin: true,
            datalayer: false,
            has_add_card_text: false,
            single_step_login: false,
            skip_card_if_exist: false,
            custom_gtm_tags: false,
            show_terms_before_redeem: false,
            details_desktop_tabs: false,
            show_special_category: false,
            send_project_id: true,
            show_card_applicability: false,
            card_applicability_fetch: "local",
            show_card_select_on_auth: false,
            special_bundle: true,
            external_login_banner: false,
            send_as_gift: false,
            applicableTag: true,
            has_maps: true,
            new_gift_card: true,
            show_only_offers: true,
            hide_categories_selection: true,
          },
          titles: {
            homepage_pagetitle: "Offers & Privileges - IDFC FIRST Bank",
            homepage_description: "",
            homepage_keywords: "",
            header_title: "",
            modal_mobile_label:
              "Enter your mobile number registered with IDFC FIRST Bank",
            card_name: "IDFC FIRST Bank Debit Card",
            platform_title: "IDFC FIRST Bank Offers and Benefits",
            concierge_name: "IDFC FIRST Bank",
            login_modal_control: "Validate Your Mobile Number",
          },
          footer_additional_content:
            "<p>Disclaimer: IDFC FIRST Bank Offers & Privileges is a platform for communication of personalized offers extended by Merchants to IDFC FIRST Bank's Customers. IDFC FIRST Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. IDFC FIRST Bank is neither guaranteeing nor making any representation. IDFC FIRST Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
          content: {
            bin_error: {
              first4_last4:
                "Dear customer, please re-check the first 4 and last 4 card number you have entered",
              first6:
                "Dear customer, please re-check the first 6 digits of your card number you have entered",
            },
            bin_invalid: {
              first4_last4:
                "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
              first6: "Invalid Card",
            },
            preset_error: "Invalid Credentials!",
          },
          email: {
            display: "banker@idfcfirstbank.com",
            val: "banker@idfcfirstbank.com",
          },
          phone: {
            display: "1800-10-888",
            val: "180010888",
          },
          rtm_enabled: true,
        };
        break;
      default:
        this.router.navigate(["404"]);
        break;
    }
  }
}
