import { ActionReducer, ActionReducerMap, MetaReducer, INIT } from '@ngrx/store';
import {countriesReducer, CountriesState } from './reducers/countries.reducer';
import {bannersReducer, BannersState } from './reducers/banners.reducer';
import {categoriesReducer, CategoriesState, categoriesDetailsReducer } from './reducers/categories.reducer';
import {offersReducer, OffersState} from './reducers/offers.reducer';
import { logout } from './actions/countries.action';

// export const reducers: ActionReducerMap<CountriesState> = {
//   countries: countriesReducer
// }




export const reducers: ActionReducerMap<any> = {
  countries: countriesReducer,
  banners: bannersReducer,
  categories: categoriesReducer,
  categoryDetails: categoriesDetailsReducer,
  offers: offersReducer
}
export const allReducers: ActionReducerMap<any> = {
  banners: bannersReducer,
  countries: countriesReducer
}

const debugMeta = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
};

const logoutMeta = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    if(action?.type == logout.type){
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
};

export const metaReducers: MetaReducer<CountriesState>[] = [debugMeta];
