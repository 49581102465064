import { Injectable } from '@angular/core';
import { GlobalConstants } from "../../common/global-variables";

@Injectable({
  providedIn: 'root'
})
export class SharedLogicService {

  constructor(
    private GlobalConstants: GlobalConstants,
  ) { }

  public isPointsDisabled(): boolean{
    const currentCardName = JSON.parse(localStorage.getItem("userCard") ?? null)?.attributes?.card_detail?.name?.toLowerCase() ?? '';
    const isPointsDisabled = this.GlobalConstants.data.cards_disabled_for_points?.some(
      card => card?.toLowerCase() === currentCardName?.toLowerCase()
    );
    return isPointsDisabled;
  }

  public isSwypCard(): boolean {
    const currentCardName = JSON.parse(localStorage.getItem("userCard") ?? null)?.attributes?.display_name?.toLowerCase() ?? '';
    return currentCardName.includes("swyp");
  }

  public getContext(isFeatured: boolean, isSwypFeatured: boolean, isNetworkOffers: boolean): string {
    if(isFeatured) return this.GlobalConstants.offersContext.featuredOffers;
    if(isSwypFeatured) return this.GlobalConstants.offersContext.swypFeaturedOffers;
    if(isNetworkOffers) return this.GlobalConstants.offersContext.networkOffers;
  }
  
}
