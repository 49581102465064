<main class="main mains" [ngClass]="cardSegment" id="main-outer" [ngStyle]="{'font-family': GlobalConstants?.data?.font_family.name}" [attr.data-client]="GlobalConstants.client" (click)="closeMerchantDropDown($event)">
    <router-outlet></router-outlet>
</main>

<div class="cdk-overlay-container" *ngIf="logginLoader">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing">
        <div class="flex align-center justify-center h100">
            <div class="page-loader"></div>
        </div>
    </div>
</div>