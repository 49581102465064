import { Component, OnInit, Inject, Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "./authentication.service";
import { environment } from "../../../environments/environment";
import { GlobalConstants } from "./global-variables";

const apiListToOmitProjectId = [
  // 'ps/v1/gift_cards',
  // 'ps/v1/categories'
];
const apiListToOmitProjectIdByForce = [
  "ps/v1/redemptions",
  "ps/v1/gift_card_bookings",
  "ps/v1/bookings",
  "pa/v1/payments/bookings_payment_info",
  "ps/v1/nearby_offers",
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: any,
    public GlobalConstants: GlobalConstants
  ) {}
  clientId: any;
  projectId: any;
  environment_type: any;
  currentUserCard: any;
  forceProjectId = false;
  sessionStorage = window.sessionStorage;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (this.document.location.hostname.includes("au-bank")) {
    //   console.log("session storage ------------------ ", window.sessionStorage);
    //   this.forceProjectId = true;
    // }
    // if (this.sessionStorage && this.sessionStorage.getItem("beta") && this.sessionStorage.getItem("beta") == 'true') {
    //   this.forceProjectId = true;
    // }
    if (
      request.url.includes("restcountries") ||
      request.url.includes("ipify.org") ||
      request.url.includes("postalpincode") ||
      request.url.includes("opencagedata") ||
      request.url.includes("ipstack")
    ) {
      return next.handle(request);
    }
    if (localStorage.getItem("userCard")) {
      this.currentUserCard = JSON.parse(localStorage.getItem("userCard"));
      request = request.clone({
        setHeaders: {
          "X-User-Card-Id": this.currentUserCard.id,
        },
      });
    }

    this.environment_type = this.document.location.hostname.includes("sandbox")
      ? "sandbox"
      : "prod";
    this.clientId =
      this.GlobalConstants.data.x_header[this.environment_type].client_id;
    if (request.headers.get("exclude_project_id")) {
      this.projectId = null;
    } else if (request.headers.get("discounted_project_id")) {
      this.projectId =
        this.GlobalConstants.data.x_header[
          this.environment_type
        ].discounted_project_id;
    } else if (
      this.currentUserCard &&
      this.currentUserCard.attributes &&
      this.currentUserCard.attributes.card_type
    ) {
      let card_type = this.currentUserCard.attributes.card_type.toLowerCase();
      if (card_type == "debit") {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].project_id_debit;
      } else if (card_type == "credit") {
        this.projectId =
          this.GlobalConstants.data.x_header[
            this.environment_type
          ].project_id_credit;
      }
    } else {
      if (localStorage.getItem("selectedCardType")) {
        this.projectId =
          localStorage.getItem("selectedCardType") == "debit-cards"
            ? this.GlobalConstants.data.x_header[this.environment_type]
                .project_id_debit
            : this.GlobalConstants.data.x_header[this.environment_type]
                .project_id_credit;
      } else {
        this.projectId =
          this.GlobalConstants.data.x_header.default_card_type == "debit"
            ? this.GlobalConstants.data.x_header[this.environment_type]
                .project_id_debit
            : this.GlobalConstants.data.x_header[this.environment_type]
                .project_id_credit;
      }
    }

    request = request.clone({
      setHeaders: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        // 'Pragma': 'no-cache',
        Expires: "-1",
        "X-Download-Options": "noopen",
        "X-Client-Id": this.clientId,
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      },
    });
    if (
      this.projectId &&
      !apiListToOmitProjectIdByForce.some((v) => request.url.includes(v)) &&
      ((this.GlobalConstants.data.personalized_config.send_project_id &&
        !apiListToOmitProjectId.some((v) => request.url.includes(v))) ||
        this.currentUserCard ||
        this.forceProjectId)
    ) {
      request = request.clone({
        setHeaders: {
          "X-Project-Id": this.projectId,
        },
      });
    }
    if (localStorage.getItem('userLocation')) {
      let userLocation = JSON.parse(localStorage.getItem('userLocation'));
      if(userLocation.country && userLocation.country.id){
        request = request.clone({
          setHeaders: {
            "X-Country-Id": userLocation.country.id,
          },
        });
      }
    }
    else if(localStorage.getItem('locationData')){
      let locationData = JSON.parse(localStorage.getItem('locationData'));
      if(locationData.id){
        request = request.clone({
          setHeaders: {
            'X-Country-Id': locationData.id
          }
        });
      }
    }
    var currentUser;
    if (localStorage.getItem("currentUser")) {
      currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if (
        currentUser.token &&
        request.headers.get("exclude_token_add") !== "true" &&
        !currentUser.skipExceptCard
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: "Bearer " + `${currentUser.token}`,
          },
        });
      } else if (
        currentUser.skipExceptCard &&
        (request.url.includes("user_cards") || request.url.includes("user"))
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: "Bearer " + `${currentUser.token}`,
          },
        });
      }
    }

    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          return response;
        },
        (error: HttpErrorResponse) => {
          return error;
        },
        () => {}
      )
    );
  }
}
