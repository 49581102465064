import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import { CreditCardMaskPipe } from 'src/app/pages/common/pipes/credit-card-mask.pipe';
import { CreditCardDirective } from 'src/app/pages/common/directives/credit-card.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IndianCurrency } from 'src/app/pages/common/pipes/indian-currency.pipe';
import { SharedModule } from 'src/app/pages/template4/shared/shared.module';
import { ConciergePaymentComponent } from './concierge-payment/concierge-payment.component';
import { LoaderModule } from 'src/app/pages/loader/loader.module';
import { SharedModule as ImmediateParentSharedModule } from "../shared/shared.module"; 

declare var Razorpay: any;
@NgModule({
  declarations: [
    PaymentComponent,
    ConciergePaymentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    LoaderModule,
    ImmediateParentSharedModule
  ],
  exports: [
    PaymentComponent,
  ],
  providers: [
  ]
})
export class PaymentModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {

    return {
        ngModule: PaymentModule,
        providers: [
            {
                provide: 'env', // you can also use InjectionToken
                useValue: environment
            },
            {
              provide: 'Razorpay',
              useValue: Razorpay
            }
        ]
    };
  }

}
