import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/pages/common/api.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root"
})
export class DetectLocation {
  userLocation: any;
  allInternationalCountries = [];
  constructor(@Inject(DOCUMENT) private document: any,private _apiService: ApiService, public toastr: ToastrService) {}
  getInternationalGeoLocation(fetchGeoLocation = true) {
    if(!localStorage.getItem("userLocation")){
      if (!this.allInternationalCountries || (this.allInternationalCountries && this.allInternationalCountries.length < 2)) {
        this.getAllInternationalCountries(fetchGeoLocation);
      }
      else{
        this.checkLocation(fetchGeoLocation);
      }
    }
  }
  
  private getAllInternationalCountries(fetchGeoLocation: boolean = true): void {
    this._apiService.getAllCountries().subscribe({
      next: (res: any) => {
        this.allInternationalCountries = res.data;
        this.checkLocation(fetchGeoLocation);
      },
      error: (err) => {
        this.toastr.error('Failed to fetch countries. Please try again later.');
      }
    });
  }
  checkLocation(fetchGeoLocation: boolean = true){
    if (localStorage.getItem("userLocation")) {
      this.userLocation = JSON.parse(localStorage.getItem("userLocation"));
      this.checkCordinates(this.userLocation.latitude + ',' + this.userLocation.longitude);
    } else {
      if (fetchGeoLocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.userLocation = position.coords;
            localStorage.setItem("userLocation", JSON.stringify({ latitude: position.coords.latitude, longitude: position.coords.longitude }));
            this.checkCordinates(this.userLocation.latitude + ',' + this.userLocation.longitude);
          }, () => {
            this.getIpStackLocation();
          });
        } else {
          this.getIpStackLocation();
        }
      }
    }
  }
  checkCordinates(param) {
    this._apiService.checkCordinates(param).subscribe(
      (res: any) => {
        if (res && res.results[0] && res.results[0].components) {
            let county = null;
            let state = null;

            let country = res.results[0].components.country.toLowerCase();
            if (res.results[0].components.county) {
              county = res.results[0].components.county.toLowerCase();
            }
            else if (res.results[0].components.city) {
              county = res.results[0].components.city.toLowerCase();
            }
            if (res.results[0].components.state) {
              state = res.results[0].components.state.toLowerCase();
            }

            this.processLocation({county:county, country:country, state: state});
        }
      },
      () => {

      }
    );
  }
  getIpStackLocation(){
    this._apiService.getIpStackLocation().subscribe((res:any) =>{
      if(res && res.latitude && res.longitude){
        this.userLocation = {
          latitude: res.latitude,
          longitude: res.longitude
        };
        let locObj = {
          state: res.region_name.toLowerCase(),
          county: res.city.toLowerCase(),
          country: res.country_name.toLowerCase(),
          latitude: res.latitude,
          longitude: res.longitude
        }
        this.processLocation(locObj)
      }
    });
  }
  processLocation(data) {
    let userCountry = this.allInternationalCountries.find((x) => { return x.attributes.name.toLowerCase() == data.country })
    if (userCountry) {
      this.userLocation = data;
      this.userLocation['country'] = userCountry;
      this._apiService.getAllCities({country_id: this.userLocation.country.id}).subscribe((res: any) => {
        if(res && res.data && res.data.length > 0){
          let userState = res.data.filter((item) => {
            return item.attributes.name.toLowerCase() == this.userLocation.state
          });
          if(userState && userState.length > 0){
            this.userLocation.state = userState[0].attributes;
          }
          localStorage.setItem("userLocation", JSON.stringify(this.userLocation));
        }
        else{
          localStorage.setItem("userLocation", JSON.stringify(this.userLocation));
        }
      });
    }
  }
}
