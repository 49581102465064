import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { DOCUMENT } from '@angular/common';
import { GlobalConstants } from '../../pages/common/global-variables';

const apiListToForceLogin = [
  '/redeem?elgble=true',
  '/create_booking',
  'carts/add_items'

];
const pageListToSkipLogin = [
  '/offers',
  '/dining'
];
const pageListToForceLogin = [
  '/contact-details'
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: any, private authenticationService: AuthenticationService,
    public GlobalConstants: GlobalConstants) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {

          console.log(request);
          const pathname = this.document.location.pathname;
          if (error.status == 401) {
            const currentUser = localStorage.getItem("currentUser");
            localStorage.removeItem('currentUser');
            localStorage.removeItem('card_verified');
            localStorage.removeItem('cardSelected');
            localStorage.removeItem('eligible_banners');
            localStorage.removeItem('locationData');
            localStorage.removeItem('currentUserCard');
            localStorage.removeItem('userCard');
            localStorage.removeItem('userCards');
            localStorage.removeItem('expire_at');
            
            const returnUrl = localStorage.getItem("returnUrl");
            const beta = sessionStorage.getItem("beta");
            const betaCredit = sessionStorage.getItem("betaCredit");
            const firstLoad = localStorage.getItem('firstLoad');
            const customer_type = localStorage.getItem('customer_type');
            const diningSearchParams = localStorage.getItem('diningSearchParams');
            sessionStorage.clear();
            localStorage.clear();
            this.authenticationService.isCardVerified.emit(false);
            if(beta){
              sessionStorage.setItem("beta", beta);
            }
            if(betaCredit){
              sessionStorage.setItem("betaCredit", betaCredit);
            }
            if(returnUrl){
              localStorage.setItem("returnUrl", returnUrl);
            }
            if(customer_type){
              localStorage.setItem("customer_type", customer_type);
            }
            if(diningSearchParams){
              localStorage.setItem("diningSearchParams", diningSearchParams);
            }

            if(apiListToForceLogin.some(v => request.url.includes(v)) || pageListToForceLogin.some(v => pathname.includes(v))){
              this.authenticationService.errorEvent.next(true);
            }
            else if(pageListToSkipLogin.some(v => pathname.includes(v))){
              // do nothing
              // location.reload();
              // this.authenticationService.reloadInit.emit(true);
            }
            else{
              this.authenticationService.errorEvent.next(true);
            }
            if(currentUser){
              this.authenticationService.reloadInit.next(true);
            }
            // if(firstLoad){
            //   this.authenticationService.reloadInit.emit(true);
            //   localStorage.removeItem('firstLoad');
            // }
          }
          if (error instanceof HttpErrorResponse) {
            return throwError(error);
          }
        }
      )
    );
  }
}
