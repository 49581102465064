import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationComponent} from "./authentication.component";
import {AddCardModalComponent} from "./addCard-modal/addCard-modal.component";
import { ClickOutsideModule } from 'ng-click-outside';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@NgModule({
    declarations: [AuthenticationComponent, AddCardModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ClickOutsideModule,
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
    exports: [AuthenticationComponent, AddCardModalComponent]
})
export class AuthenticationModule {
}
