import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";

import { FooterComponent } from "./footer.component";
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    LazyLoadImageModule
  ],
  exports: [ FooterComponent ]
})
export class FooterModule { }
