import { Router, Scroll, NavigationEnd } from "@angular/router";
import { AuthenticationService } from "./pages/common/authentication.service";
import { User } from "./pages/common/user.modal";
import {
  Component,
  Renderer2,
  Inject,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { DOCUMENT, ViewportScroller } from "@angular/common";
import "rxjs/add/operator/distinctUntilChanged";
import { GlobalConstants } from "./pages/common/global-variables";
import { environment } from "../environments/environment";
import { filter, delay } from "rxjs/operators";
// import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [GlobalConstants],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  isDesktopDevice = false;
  isTablet = false;
  isMobile = false;
  hasInitalGTMEventFired: boolean;
  public isUserAuthenticated = false;
  @HostListener("window:resize", ["$event"])
  onResize() {
    if ((window as any).innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
    } else if ((window as any).innerWidth > 992) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
    } else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
    }
  }
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public GlobalConstants: GlobalConstants,
    @Inject(DOCUMENT) private document: any,
    private _renderer2: Renderer2,
    viewportScroller: ViewportScroller
  ) // private update: SwUpdate,
  {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (!this.hasInitalGTMEventFired) {
          this.hasInitalGTMEventFired = true;
          return;
        }
        window?.dataLayer?.push({event: 'pageview'});
      }
    });
    router.events
    .pipe(filter((e): e is Scroll => e instanceof Scroll))
    .pipe(delay(1))   // <--------------------------- This line
    .subscribe((e) => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
    this.onResize();
    // this.updateClient();
    localStorage.setItem("firstLoad", "true");
    // add google map api
    // if(this.GlobalConstants && this.GlobalConstants.data && this.GlobalConstants.data.personalized_config && this.GlobalConstants.data.personalized_config.has_maps){
    //   this.addScript(`https://maps.googleapis.com/maps/api/js?key=${environment.gmApiKey}`);
    // }

    if (
      this.GlobalConstants &&
      this.GlobalConstants.data &&
      this.GlobalConstants.data.font_family
    ) {
      this.addStyleSheet(this.GlobalConstants.data.font_family.link);
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/"]);
  }
  // updateClient() {
  //   if (!this.update.isEnabled) {
  //     console.log('Not Enabled');
  //     return;
  //   }
  //   this.update.available.subscribe((event) => {
  //      this.update.activateUpdate().then(() => location.reload());
  //   });
  //
  //   this.update.activated.subscribe((event) => {
  //     console.log(`current`, event.previous, `available `, event.current);
  //   });
  // }
  addStyleSheet(fontLink) {
    var headID = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.id = "widget_styles";
    headID.appendChild(link);

    link.href = fontLink;
  }
  addAnalyticsScript() {
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    var path = "https://www.googletagmanager.com/gtag/js?id=";
    var window_loc = (window as any).location.href.toLowerCase();
    var config;
    var config_tag;
    if (window_loc.includes("idfc")) {
      config = "UA-178878482-1";
      config_tag = ["GTM-W56NWSC"];
    } else {
      config = [];
      config_tag = [];
    }
    script.src = path + config;
    script.type = "text/javascript";

    head.appendChild(script);

    (window as any).dataLayer = (window as any).dataLayer || [];

    this.gtag({ js: new Date() });
    this.gtag({ config: config });

    config_tag.forEach(function (conf) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j["async"] = true;
        j["src"] = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", conf);
    });
  }
  gtag(arg) {
    (window as any).dataLayer.push(arg);
  }
  addScript(linkurl) {
    // let script = this._renderer2.createElement('script');
    // script.src = linkurl;
    // script.async = true;
    // this._renderer2.appendChild(this.document.head, script);

    var headID = document.getElementsByTagName("head")[0];
    var link = document.createElement("script");
    link.src = linkurl;
    link.async = true;
    headID.appendChild(link);
  }

  displayFooter() {
    if (this.GlobalConstants.client === "idfc-bank-gi") {
      return false;
    }
    const noFooterPaths = [
      "giftcards/details",
      "giftcards/checkout",
      // 'giftcards/checkout-message',
      "offers/offer-details",
      "memberships/details",
      "memberships/booking",
      // 'memberships/checkout-message',
      "dining/details",
      "dining/contact-details",
      "thankyou",
      "offer-details"
    ];
    const url = this.router.url;
    if (noFooterPaths.find((path) => url.includes(path))) {
      return false;
    }
    return true;
  }
}
