<div class="footer-sec">
  <div class="mar-top-8 d-flex flex-row justify-content-around align-items-start">
    <div class="
        d-flex
        flex-column
        justify-content-start
        align-items-center
        home-width
        mar-top-3
      " (click)="switchTab(tabs.Home)" id="home_nav">
      <!-- <svg
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="40%"
        height="40%"
      >
        <path
          d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z M 12 4.7910156 L 18 10.191406 L 18 11 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 L 12 4.7910156 z"
        />
      </svg> -->
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="#0E202E"
        class="mar-bot-3" [ngClass]="{'selected-tab-home' : selectedTab === tabs.Home}">
        <path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" />
      </svg>
      <div>
        <label class="center-align-label"><span class="wrap-label c-777 f500 fnt-11"
            [ngClass]="{'selected-tab' : selectedTab === tabs.Home}">Home</span></label>
      </div>
    </div>
    <div *ngIf="!GlobalConstants.data.personalized_config.show_only_offers" class="
        d-flex
        flex-column
        justify-content-start
        align-items-center
      " (click)="switchTab(tabs.Gifts)" id="redeem_nav"
      [ngClass]="isUserAuthenticated && isFirstPrivate ? 'tab-post-login-width' : 'tab-width'">
      <div class="icon icon-font icon-mail" [ngClass]="{'selected-tab' : selectedTab === tabs.Gifts}"></div>
      <div>
        <label class="center-align-label"><span class="wrap-label c-777 f500 fnt-11"
            [ngClass]="{'selected-tab' : selectedTab === tabs.Gifts}">Redeem Rewards</span></label>
      </div>
    </div>
    <div class="
        d-flex
        flex-column
        justify-content-start
        align-items-center

      " (click)="switchTab(tabs.Offers)" id="offers_nav"
      [ngClass]="isUserAuthenticated && isFirstPrivate ? 'tab-post-login-width' : 'tab-width'">
      <div class="icon icon-font icon-cart" [ngClass]="{'selected-tab' : selectedTab === tabs.Offers}"></div>
      <div>
        <label class="center-align-label"><span class="wrap-label c-777 f500 fnt-11"
            [ngClass]="{'selected-tab' : selectedTab === tabs.Offers}">Deals & Offers</span></label>
      </div>
    </div>
    <div *ngIf="!GlobalConstants.data.personalized_config.show_only_offers" class="
        d-flex
        flex-column
        justify-content-start
        align-items-center

      " (click)="switchTab(tabs.Dining)" id="dining_nav"
      [ngClass]="isUserAuthenticated && isFirstPrivate ? 'tab-post-login-width' : 'tab-width'">
      <div class="icon icon-font icon-dining" [ngClass]="{'selected-tab' : selectedTab === tabs.Dining}"></div>
      <div>
        <label class="center-align-label"><span class="wrap-label c-777 f500 fnt-11"
            [ngClass]="{'selected-tab' : selectedTab === tabs.Dining}">Reserve a Table</span></label>
      </div>
    </div>
    <div *ngIf="isUserAuthenticated && isFirstPrivate" class="
      d-flex
      flex-column
      justify-content-start
      align-items-center
    " (click)="switchTab(tabs.Charity)" id="donate_nav"
      [ngClass]="isUserAuthenticated && isFirstPrivate ? 'tab-post-login-width' : 'tab-width'">
      <div class="icon icon-font icon-donation" [ngClass]="{'selected-tab' : selectedTab === tabs.Charity}"></div>
      <div>
        <label class="center-align-label"><span class="wrap-label c-777 f500 fnt-11"
            [ngClass]="{'selected-tab' : selectedTab === tabs.Charity} ">Donate Now</span></label>
      </div>
    </div>
  </div>
</div>