<footer class="s-footer bg-fff" [class.footer-bot-pad] = "GlobalConstants.client === 'idfc-bank'" *ngIf = "!GlobalConstants.data.footer_type && GlobalConstants.client != 'au-bank'">
  <div class="container-fluid no-margin">
    <div class="flex space-between no-mobile">
      <div class=" copywrite fnt-13 desktop-left-content">
        <div class="flex align-center">
            <div class="foot-logo left" *ngIf = "GlobalConstants.data.personalized_config.footer_logo"><img class=" img-fluid" [lazyLoad] = "GlobalConstants.data.logo.footerurl"></div>
            <div class="c383838 f300 mar-top-6 copy-stuff left">
              <p class="no-margin">&copy; {{currentYear}} PerksHub</p>
            </div>
        </div>

        <div *ngIf = "GlobalConstants.data.footer_additional_content != ''" [innerHtml] = "GlobalConstants.data.footer_additional_content" class="fnt-11 f500 c777 mar-top-10"></div>

      </div>
      <div class="foot-links pad-top-15 desktop-links">
        <div class="flex flex-end">
          <div class="foot-link fnt-13">
            <a *ngIf = "!GlobalConstants.data.assets.termsLink" href="/info/terms-and-conditions" target="_blank" class="c3A4149">Terms & Conditions</a>
            <a *ngIf = "GlobalConstants.data.assets.termsLink" href="{{GlobalConstants.data.assets.termsLink}}" target="_blank" class="c3A4149">Terms & Conditions</a>
          </div>
          <div class="foot-link fnt-13"><a href="/info/privacy-policy" target="_blank" class="c3A4149">Privacy Policy</a></div>
          <div class="foot-link fnt-13 pad-rht-0"><a href="/info/customer-support" target="_blank" class="c3A4149">Customer Support</a></div>
        </div>
        <div class="flex flex-end mar-top-35 copy-copy-stuff">
          <div class="download-logos" *ngIf = "GlobalConstants.data.download_app_link.footer">
            <div class="fnt-9">Download App</div>
            <div class="flex justify-center mar-top-6">
              <a *ngIf = "GlobalConstants.data.download_app_link.android_link != null" class="android-icon" href="{{GlobalConstants.data.download_app_link.android_link}}" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 413.137 413.137" style="enable-background:new 0 0 413.137 413.137;" xml:space="preserve">
                <g>
                	<path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M311.358,136.395H101.779c-4.662,0-8.441,3.779-8.441,8.441v175.749   c0,4.662,3.779,8.441,8.441,8.441h37.363v59.228c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883   v-59.228h34.803v59.228c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883v-59.228h37.882   c4.662,0,8.441-3.779,8.441-8.441V144.836C319.799,140.174,316.02,136.395,311.358,136.395z"/>
                	<path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M57.856,136.354L57.856,136.354c-13.742,0-24.883,11.14-24.883,24.883v101.065   c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883V161.237   C82.738,147.495,71.598,136.354,57.856,136.354z"/>
                	<path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M355.281,136.354L355.281,136.354c-13.742,0-24.883,11.14-24.883,24.883v101.065   c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883V161.237   C380.164,147.495,369.024,136.354,355.281,136.354z"/>
                	<path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M103.475,124.069h205.692c5.366,0,9.368-4.943,8.266-10.195   c-6.804-32.428-27.45-59.756-55.465-75.543l17.584-31.727c1.19-2.148,0.414-4.855-1.734-6.045   c-2.153-1.193-4.856-0.414-6.046,1.734l-17.717,31.966c-14.511-6.734-30.683-10.495-47.734-10.495   c-17.052,0-33.224,3.761-47.735,10.495L140.869,2.292c-1.191-2.149-3.898-2.924-6.045-1.734c-2.148,1.19-2.924,3.897-1.734,6.045   l17.584,31.727c-28.015,15.788-48.661,43.115-55.465,75.544C94.106,119.126,98.108,124.069,103.475,124.069z M267.697,76.786   c0,5.282-4.282,9.565-9.565,9.565c-5.282,0-9.565-4.282-9.565-9.565c0-5.282,4.282-9.565,9.565-9.565   C263.415,67.221,267.697,71.504,267.697,76.786z M154.508,67.221c5.282,0,9.565,4.282,9.565,9.565c0,5.282-4.282,9.565-9.565,9.565   c-5.282,0-9.565-4.282-9.565-9.565C144.943,71.504,149.225,67.221,154.508,67.221z"/>
                </g>
                </svg>
              </a>
              <a *ngIf = "GlobalConstants.data.download_app_link.apple_link != null" class="apple-icon" href="{{GlobalConstants.data.download_app_link.apple_link}}" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 549.875 549.876" style="enable-background:new 0 0 549.875 549.876;" xml:space="preserve">
                <g>
                	<g>
                		<path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M340.535,104.42c13.881-13.874,24.125-29.07,30.735-45.594c6.389-16.524,9.584-31.5,9.584-44.945    c0-0.875-0.056-1.989-0.166-3.305c-0.116-1.316-0.165-2.411-0.165-3.305c-0.22-0.661-0.495-1.873-0.826-3.642    c-0.33-1.756-0.605-2.968-0.826-3.629c-38.776,9.033-66.311,25.337-82.613,48.911c-16.524,23.789-25.117,52.1-25.778,84.927    c14.755-1.328,26.211-3.188,34.37-5.612C316.747,124.249,328.638,116.323,340.535,104.42z"/>
                		<path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M452.892,359.868c-15.202-21.799-22.803-46.365-22.803-73.696c0-24.891,7.154-47.688,21.48-68.404    c7.712-11.23,20.27-24.229,37.675-38.997c-11.456-14.094-22.913-25.104-34.369-33.048c-20.711-14.303-44.175-21.481-70.387-21.481    c-15.643,0-34.7,3.758-57.173,11.243c-21.592,7.497-37.344,11.23-47.253,11.23c-7.49,0-22.692-3.305-45.606-9.914    c-23.133-6.61-42.625-9.915-58.489-9.915c-37.895,0-69.18,15.863-93.85,47.595c-24.896,32.167-37.344,73.36-37.344,123.587    c0,53.312,16.193,108.716,48.581,166.226c32.822,57.057,65.979,85.582,99.468,85.582c11.236,0,25.771-3.745,43.617-11.23    c17.846-7.271,33.482-10.905,46.922-10.905c14.321,0,30.949,3.525,49.902,10.569c20.043,7.05,35.466,10.569,46.262,10.569    c28.194,0,56.506-21.586,84.927-64.762c18.507-27.534,32.057-55.08,40.649-82.614C485.494,395.561,468.094,381.68,452.892,359.868    z"/>
                	</g>
                </g>
                </svg>
              </a>
            </div>
          </div>
          <div class="poshvine-logo"><img [src]="'./assets/img/other/powered-by.svg'" alt="pci-dss-logo"></div>
          <div class="pci-logo flex align-center"><img [src]="'./assets/img/other/pci.png'" alt="pci-dss-logo"></div>
          <!-- <div class="pci-logo flex lg" *ngIf = "GlobalConstants.client  == 'rupay-smebenefits'" ><img [src]="'./assets/img/other/NPCI-Logo.png'" alt="pci-dss-logo" class="npci"></div> -->
        </div>
      </div>
    </div>

    <div class="mob-stuff flex">
      <div class="flex">

      </div>
      <div class="flex-l">
        <div class="flex pad-bot-7">
          <div *ngIf = "GlobalConstants.data.personalized_config.footer_logo">
            <div class="foot-logo"><img class=" img-fluid" [lazyLoad] = "GlobalConstants.data.logo.footerurl"></div>
          </div>
          <p class="fnt-12 flex align-center mob-copywrite mar-bot-0">&copy; {{currentYear}} PerksHub</p>
        </div>

        <div class="flex m-foot-links">

          <div class="foot-link fnt-11 no-pad-lf">
            <a *ngIf = "!GlobalConstants.data.assets.termsLink" target="_blank" href="/info/terms-and-conditions" class="c3A4149">Terms</a>
            <a *ngIf = "GlobalConstants.data.assets.termsLink" href="{{GlobalConstants.data.assets.termsLink}}" target="_blank" class="c3A4149">Terms & Conditions</a>
          </div>
          <div class="foot-link fnt-11"><a href="/info/privacy-policy" target="_blank" class="c3A4149">Privacy Policy</a></div>
          <div class="foot-link fnt-11"><a href="/info/customer-support" target="_blank" class="c3A4149">Contact</a></div>
        </div>
      </div>

      <div class="flex-r flex-end justify-end ">
        <div class="download-logos flex-r" *ngIf = "GlobalConstants.data.download_app_link.footer">

          <div class="flex justify-end mar-bot-10 mob-logo-contain flex-r" *ngIf = "GlobalConstants.data.download_app_link.footer">
            <a *ngIf = "GlobalConstants.data.download_app_link.android_link != null" class="android-icon" href="{{GlobalConstants.data.download_app_link.android_link}}" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 413.137 413.137" style="enable-background:new 0 0 413.137 413.137;" xml:space="preserve">
              <g>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M311.358,136.395H101.779c-4.662,0-8.441,3.779-8.441,8.441v175.749   c0,4.662,3.779,8.441,8.441,8.441h37.363v59.228c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883   v-59.228h34.803v59.228c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883v-59.228h37.882   c4.662,0,8.441-3.779,8.441-8.441V144.836C319.799,140.174,316.02,136.395,311.358,136.395z"/>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M57.856,136.354L57.856,136.354c-13.742,0-24.883,11.14-24.883,24.883v101.065   c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883V161.237   C82.738,147.495,71.598,136.354,57.856,136.354z"/>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M355.281,136.354L355.281,136.354c-13.742,0-24.883,11.14-24.883,24.883v101.065   c0,13.742,11.14,24.883,24.883,24.883l0,0c13.742,0,24.883-11.14,24.883-24.883V161.237   C380.164,147.495,369.024,136.354,355.281,136.354z"/>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M103.475,124.069h205.692c5.366,0,9.368-4.943,8.266-10.195   c-6.804-32.428-27.45-59.756-55.465-75.543l17.584-31.727c1.19-2.148,0.414-4.855-1.734-6.045   c-2.153-1.193-4.856-0.414-6.046,1.734l-17.717,31.966c-14.511-6.734-30.683-10.495-47.734-10.495   c-17.052,0-33.224,3.761-47.735,10.495L140.869,2.292c-1.191-2.149-3.898-2.924-6.045-1.734c-2.148,1.19-2.924,3.897-1.734,6.045   l17.584,31.727c-28.015,15.788-48.661,43.115-55.465,75.544C94.106,119.126,98.108,124.069,103.475,124.069z M267.697,76.786   c0,5.282-4.282,9.565-9.565,9.565c-5.282,0-9.565-4.282-9.565-9.565c0-5.282,4.282-9.565,9.565-9.565   C263.415,67.221,267.697,71.504,267.697,76.786z M154.508,67.221c5.282,0,9.565,4.282,9.565,9.565c0,5.282-4.282,9.565-9.565,9.565   c-5.282,0-9.565-4.282-9.565-9.565C144.943,71.504,149.225,67.221,154.508,67.221z"/>
              </g>
              </svg>
            </a>
            <a *ngIf = "GlobalConstants.data.download_app_link.apple_link != null" class="apple-icon" href="{{GlobalConstants.data.download_app_link.apple_link}}" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 549.875 549.876" style="enable-background:new 0 0 549.875 549.876;" xml:space="preserve">
              <g>
                <g>
                  <path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M340.535,104.42c13.881-13.874,24.125-29.07,30.735-45.594c6.389-16.524,9.584-31.5,9.584-44.945    c0-0.875-0.056-1.989-0.166-3.305c-0.116-1.316-0.165-2.411-0.165-3.305c-0.22-0.661-0.495-1.873-0.826-3.642    c-0.33-1.756-0.605-2.968-0.826-3.629c-38.776,9.033-66.311,25.337-82.613,48.911c-16.524,23.789-25.117,52.1-25.778,84.927    c14.755-1.328,26.211-3.188,34.37-5.612C316.747,124.249,328.638,116.323,340.535,104.42z"/>
                  <path [ngStyle]="{'fill': GlobalConstants.data.colors.active_text_color}" d="M452.892,359.868c-15.202-21.799-22.803-46.365-22.803-73.696c0-24.891,7.154-47.688,21.48-68.404    c7.712-11.23,20.27-24.229,37.675-38.997c-11.456-14.094-22.913-25.104-34.369-33.048c-20.711-14.303-44.175-21.481-70.387-21.481    c-15.643,0-34.7,3.758-57.173,11.243c-21.592,7.497-37.344,11.23-47.253,11.23c-7.49,0-22.692-3.305-45.606-9.914    c-23.133-6.61-42.625-9.915-58.489-9.915c-37.895,0-69.18,15.863-93.85,47.595c-24.896,32.167-37.344,73.36-37.344,123.587    c0,53.312,16.193,108.716,48.581,166.226c32.822,57.057,65.979,85.582,99.468,85.582c11.236,0,25.771-3.745,43.617-11.23    c17.846-7.271,33.482-10.905,46.922-10.905c14.321,0,30.949,3.525,49.902,10.569c20.043,7.05,35.466,10.569,46.262,10.569    c28.194,0,56.506-21.586,84.927-64.762c18.507-27.534,32.057-55.08,40.649-82.614C485.494,395.561,468.094,381.68,452.892,359.868    z"/>
                </g>
              </g>
              </svg>
            </a>
          </div>
        </div>
        <div class="flex flex-end  pad-bot-1 flex-r">
          <div class="poshvine-logo flex lg"><img [src]="'./assets/img/other/powered-by.svg'" alt="pci-dss-logo"></div>
          <div class="pci-logo flex lg"><img [src]="'./assets/img/other/pci.png'" alt="pci-dss-logo"></div>
          <!-- <div class="pci-logo flex lg"  *ngIf = "GlobalConstants.client  == 'rupay-smebenefits'"><img [src]="'./assets/img/other/NPCI-Logo.png'" alt="pci-dss-logo"></div> -->
        </div>
      </div>
    </div>
  </div>
</footer>
