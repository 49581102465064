<div class="relative">
  <div *ngIf="!showNoCards">
      <div class="close-box pointer logo mar-bot-20">
        <img [ngStyle]="{'height': GlobalConstants.data.logo.head_logo_height+ 'px'}"
          [src]="GlobalConstants.data.logo.modal_url" class="img-fluid login-client-logo">
      </div>

      <section *ngIf="fetchingCard">
        <img class="fetchingDataLoader" src="./assets/img/icons/spin.gif">
      </section>

      <section class="step-one" *ngIf="GlobalConstants.data.login_theme == 1 && stepToShow == 'mobile'">
        <form [formGroup]="createWithUserForm" (submit)="authenticateUser($event)" autocomplete="newpassword">
          <div class="title">{{GlobalConstants.data.titles.login_modal_control}}</div>
          <div class="row fields pad-top-20">
            <div class="col-sm-12">
              <div class="clearfix field-wrap">
                <label class="mar-bot-10">
                  <span
                    *ngIf="GlobalConstants.data.titles.modal_mobile_label == '' || GlobalConstants.data.titles.modal_mobile_label == null">Mobile
                    Number</span>
                  <span
                    *ngIf="GlobalConstants.data.titles.modal_mobile_label != '' && GlobalConstants.data.titles.modal_mobile_label != null">{{GlobalConstants.data.titles.modal_mobile_label}}</span>
                </label>
                <div class="mobile-block">
                  <div class="country-block">
                    <div class="w100 no-padding-left pd-r7 main-custom-dropdown-box s-input-box">
                      <div class="custom-select-box relative" *ngIf="selectedCountry"
                        (clickOutside)="isCountryDropdownOpen = false"
                        [exclude]="'.country-item , .more'">
                        <div (click)="isCountryDropdownOpen = true;" class="btn filter-btn">
                          <img [src]="selectedCountry.flag_url" alt="Flag"
                            class="filter-ic relative selected-country-flag" />
                          <div class="dropdown-icon">
                            <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4 4L0 0L8 6.28116e-07L4 4Z" fill="#98A2B4" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="dropdown custom-dropdown country-list">
                        <div class="dropdown-menu filter-dropdown" [ngClass]="{'show' : isCountryDropdownOpen == true}">
                          <div class="dropdown-body">
                            <div class="block1 flex relative">
                              <div class="block-lf w100">
                                <ng-container *ngIf="GlobalConstants.data.personalized_config.smart_country_dropdown">
                                  <div class="country-item pointer"
                                    *ngFor="let country of popularCountries; let i = index"
                                    (click)="setCountry(country)"
                                    [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                                    <div class="flex relative item-inner">
                                      <div class="country-img">
                                        <img [src]="country.attributes.flag_url" class="img-fluid">
                                      </div>
                                      <div class="item-name flex align-center c0E202E fnt-14">
                                        <span>{{country.attributes.name}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div *ngIf="!showMoreCountries" class="separator-line"></div>
                                  <div class="country-item pointer text-center more" (click)="showMoreCountries = !showMoreCountries" *ngIf="!showMoreCountries">
                                    More
                                  </div>
                                  <ng-container *ngIf="showMoreCountries">
                                    <div class="country-item pointer"
                                    *ngFor="let country of otherCountries; let i = index" (click)="setCountry(country)"
                                    [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                                    <div class="flex relative item-inner">
                                      <div class="country-img">
                                        <img [src]="country.attributes.flag_url" class="img-fluid">
                                      </div>
                                      <div class="item-name flex align-center c0E202E fnt-14">
                                        <span>{{country.attributes.name}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!GlobalConstants.data.personalized_config.smart_country_dropdown">
                                  <div class="country-item pointer" *ngFor="let country of allCountries; let i = index"
                                    (click)="setCountry(country)"
                                    [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                                    <div class="flex relative item-inner">
                                      <div class="country-img">
                                        <img [src]="country.attributes.flag_url" class="img-fluid">
                                      </div>
                                      <div class="item-name flex align-center c0E202E fnt-14">
                                        <span>{{country.attributes.name}}</span>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-input relative">
                    <input formControlName="mobile" class="form-control h100" [(ngModel)]="mobNum" type="text"
                      placeholder="Mobile Number" maxlength="{{selectedCountry?.max_mobile_digit}}"
                      minlength="{{selectedCountry?.min_mobile_digit}}"
                      (input)="removeLetter($event.target.value);userSearch();" />
                    <div class="small-loader input-load" *ngIf="searchLoader || isload1"></div>
                  </div>
                </div>
              </div>

              <div class="clearfix field-wrap mar-top-30"
                *ngIf="(!ifExistingUser) && ((userAuthMode != 'preset_bin' && GlobalConstants.data.personalized_config.allow_user_create) || GlobalConstants?.data?.content?.preset_error)">
                <div class="error-txt fnt-12 f500" *ngIf="GlobalConstants?.data?.content?.preset_error"
                  [innerHTML]="GlobalConstants?.data?.content?.preset_error">

                </div>
                <div *ngIf="userAuthMode != 'preset_bin' && GlobalConstants.data.personalized_config.allow_user_create">
                  <label> {{ GlobalConstants.data.titles.modal_email_label ||
                    'Enter your email address (optional)'}}</label>
                  <div class="input-block">
                    <input formControlName="email" class="form-control" type="text"
                      (input)="errorRequestingOtpMsg = ''; isload1 = false;" />
                  </div>
                  <div *ngIf="f.email.errors" class="error-txt fnt-12 f500">
                    Please enter a valid email address
                  </div>
                </div>
              </div>

              <div class="clearfix field-wrap f600 au-otp-line" [ngStyle]="{'color': GlobalConstants.data.colors.mainBtn_color}"
                *ngIf="GlobalConstants.data.personalized_config.otp_indication_msg && mobileInputBtnActive">
                An OTP will be sent to your registered mobile number and email address (if any) for verification
              </div>

              <div class="clearfix">
                <!-- <div *ngIf="submitted && f.email.errors" class="error-txt fnt-12 f500">
                    Please enter a valid email address
                </div> -->
                <div *ngIf="invalidUser" class="errorsr-txt fnt-12 f500 otp-error">Your details do not match with our
                  records. Please re-enter your details, or contact the {{GlobalConstants.data.titles.concierge_name}}
                  Concierge.</div>
                <div class="error-txt mt-3 otp-error" *ngIf="errorRequestingOtp">{{errorRequestingOtpMsg}}</div>
              </div>
              <div>
                <div class="clearfix continue-btn-block" *ngIf="userAuthMode == 'preset_bin'">
                  <button class="relative btn" type="submit"
                    [ngStyle]="{'background': GlobalConstants.data.colors.mainBtn_color}"
                    [disabled]="errorRequestingOtp"
                    *ngIf="mobileInputBtnActive && ifExistingUser">Continue <img *ngIf="isload1" class="loader"
                      src="./assets/img/icons/spin.gif"></button>
                  <button type="btn" *ngIf="!mobileInputBtnActive || !ifExistingUser"
                    disabled="disabled">Continue</button>
                </div>
                <div class="clearfix continue-btn-block" *ngIf="userAuthMode != 'preset_bin'">
                  <button id = "mobile-continue-btn" class="relative btn" type="submit"
                    [ngStyle]="{'background': GlobalConstants.data.colors.mainBtn_color}" *ngIf="mobileInputBtnActive"
                    [disabled]="isload1 || errorRequestingOtp">Continue <img *ngIf="isload1" class="loader"
                      src="./assets/img/icons/spin.gif"></button>

                  <button type="btn" *ngIf="!mobileInputBtnActive" disabled="disabled">Continue</button>
                  <div id = "i-do-later" class="close-authentication pointer underline mt-2" style="width: fit-content;"
                    *ngIf="GlobalConstants.data.personalized_config.cancelable_auth"
                    (click)="doLater();closeAuthentication()">I'll do this later ></div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </section>
      <section class="step-two otp-input" *ngIf="GlobalConstants.data.login_theme == 1 && stepToShow == 'otp'">
        <form [formGroup]="validateUserOtpForm">
          <div class="title" style="font-size: 16px;">
            <span *ngIf="!GlobalConstants.data.personalized_config.enter_otp_line_2"> Enter the 6 digit OTP sent to +91
              *******{{mobNum | slice:7:10 }} </span>
            <span *ngIf="GlobalConstants.data.personalized_config.enter_otp_line_2">Enter the 6 digit OTP sent to your
              registered mobile number and email address (if any) </span>
            <span class="back-btn pointer relative fnt-13"
              [ngStyle]="{'color': GlobalConstants.data.colors.mainBtn_color}" (click)="resetLoginAgain()">(Edit)</span>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="otp-field">
                <input formControlName="otp" id="otp-field" class="form-control" type="" name=""
                  (input)="validateUserOtp(); invalidOtp = false;" max="6" maxlength="6" [(ngModel)]="otpInp" />
                <div class="input-border flex">
                  <span class="num-1" [ngClass]="{'active' : otpInp?.length == 0 || !otpInp}"></span>
                  <span class="num-2" [ngClass]="{'active' : otpInp?.length == 1}"></span>
                  <span class="num-3" [ngClass]="{'active' : otpInp?.length == 2}"></span>
                  <span class="num-4" [ngClass]="{'active' : otpInp?.length == 3}"></span>
                  <span class="num-5" [ngClass]="{'active' : otpInp?.length == 4}"></span>
                  <span class="num-6" [ngClass]="{'active' : otpInp?.length == 5}"></span>
                </div>
                <div class="just"></div>
                <div class="check" *ngIf="validOtp">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM6 11.25L2.25 7.5L3.3075 6.4425L6 9.1275L11.6925 3.435L12.75 4.5L6 11.25Z"
                      fill="#15C9B7" />
                  </svg>
                </div>
                <div class="clear pointer" (click)="clearOtp(); invalidOtp = false" *ngIf="!otpVerifying">
                  X
                </div>
                <div class="clear otp-verifying" *ngIf="otpVerifying">
                  <img class="loader" src="./assets/img/icons/spin.gif">
                </div>
              </div>
              <div class="help-block f500 fnt-13 mar-top-12" *ngIf="viewTimer">Resend Code in {{timerToShow}}</div>
              <div class="check error-txt" *ngIf="invalidOtp">
                Entered Otp is invalid.
              </div>
              <div class="check error-txt" *ngIf="errorRequestingOtp">
                {{errorRequestingOtpMsg}}
              </div>
              <button
                [ngStyle]="{'color': GlobalConstants.data.colors.mainBtn_color, 'border-color': GlobalConstants.data.colors.mainBtn_color}"
                (click)="authenticateUser($event, false); invalidOtp = false; errorRequestingOtp = false;"
                class="resend-otp pointer fnt-12 f400 mar-top-4" *ngIf="!viewTimer || viewTimer.length == 0">Resend
                Code</button>
            </div>
          </div>
        </form>
      </section>
      <!-- <section class="step-three" [class.merged-step]="GlobalConstants.data.personalized_config.single_step_login"> -->
      <section class="step-three"
        *ngIf="GlobalConstants.data.login_theme == 1 && (stepToShow == 'card' && GlobalConstants.data.personalized_config.has_card == true)">
        <form [formGroup]="addCardForm" (submit)="addCardFormSubmit()">
          <div class="title">
            Enter
            <span *ngIf="GlobalConstants.data.card_num_type == 'first4_last4'">first 4 & last 4 digits</span>
            <span *ngIf="GlobalConstants.data.card_num_type == 'first6'">first 6 digits</span>
            <span *ngIf="GlobalConstants.data.card_num_type == 'first8'">first 8 digits</span>
            <span *ngIf="GlobalConstants.data.card_num_type == 'last4'">last 4 digits</span>
            <span>of your {{GlobalConstants.data.titles.card_name}}</span>
          </div>
          <div class="card-block" *ngIf="GlobalConstants.data.card_num_type == 'first4_last4'">
            <!-- <div class="small-loader input-load merged-card-loader" *ngIf="isload2"></div> -->
            <label>Card Number</label>
            <div class="flex main-flex">
              <div class="flex-3 pd-r7">
                <input type="text" #firstFocusable id="first_four_card_digits" class="form-control"
                  (keyup)="onKeyph($event); keytab($event,focusable)" formControlName="binFirst4"
                  autocomplete="new-password" minlength="4" maxlength="4" (input)="checkbtn()" required>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" placeholder="XXXX" autocomplete="none"
                    disabled>
                </div>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" (keyup)="onKeyph($event)"
                    placeholder="XXXX" disabled>
                </div>
              </div>
              <div class="flex-3 pd-l7">
                <input type="text" #focusable id="last_four_card_digits" class="form-control" formControlName="binLast4"
                  autocomplete="new-password" minlength="4" maxlength="4"
                  (keyup)="onKeyph($event);backkeytab($event, firstFocusable)" (input)="checkbtn()" required>
              </div>
            </div>
          </div>

          <div class="card-block relative" *ngIf="GlobalConstants.data.card_num_type == 'first6'">
            <div class="small-loader input-load merged-card-loader" *ngIf="isload2"></div>
            <label>Card Number</label>
            <div class="flex main-flex">
              <div class="flex-3 pd-r7">
                <input type="text" #firstFocusable id="first_four_card_digits" class="form-control four-digit"
                  (keyup)="onKeyph($event)" formControlName="binFirst4" autocomplete="new-password" minlength="4"
                  maxlength="4" (keyup)="keytab($event,focusable)" (input)="checkbtn()" required>
              </div>
              <div class="flex-3 pd-l7">
                <input type="text" #focusable id="last_four_card_digits" class="form-control two-digit"
                  formControlName="binLast4" autocomplete="new-password" minlength="2" maxlength="2"
                  (keyup)="onKeyph($event);backkeytab($event, firstFocusable)" (input)="checkbtn()" required>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control two-digit-next disabled text-center" placeholder="XX  XXXX"
                    autocomplete="none" disabled>
                </div>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control disabled text-center" (keyup)="onKeyph($event)"
                    placeholder="XXXX" disabled>
                </div>
              </div>

            </div>
          </div>

          <div class="card-block" *ngIf="GlobalConstants.data.card_num_type == 'first8'">
            <div class="small-loader input-load merged-card-loader" *ngIf="isload2"></div>
            <label>Card Number</label>
            <div class="flex main-flex">
              <div class="flex-3 pd-r7">
                <input type="text" #firstFocusable id="first_four_card_digits" class="form-control"
                  (keyup)="onKeyph($event); keytab($event, focusable)" formControlName="binFirst4"
                  autocomplete="new-password" minlength="4" maxlength="4" (input)="checkbtn()" required>
              </div>
              <div class="flex-3 pd-l7">
                <input type="text" #focusable id="last_four_card_digits" class="form-control" formControlName="binLast4"
                  autocomplete="new-password" minlength="4" maxlength="4"
                  (keyup)="onKeyph($event);backkeytab($event, firstFocusable)" (input)="checkbtn()" required>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" placeholder="XXXX" autocomplete="none"
                    disabled>
                </div>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" (keyup)="onKeyph($event)"
                    placeholder="XXXX" disabled>
                </div>
              </div>

            </div>
          </div>
          <div class="card-block" *ngIf="GlobalConstants.data.card_num_type == 'last4'">

            <div class="flex main-flex">
              <div class="flex-3 pd-r7">
                <input type="text" class="form-control text-center disabled" placeholder="XXXX" autocomplete="none"
                  disabled>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" placeholder="XXXX" autocomplete="none"
                    disabled>
                </div>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" (keyup)="onKeyph($event)"
                    placeholder="XXXX" disabled>
                </div>
              </div>
              <div class="flex-3 pd-l7">
                <input type="text" #focusable id="last_four_card_digits" class="form-control" formControlName="binLast4"
                  autocomplete="new-password" minlength="4" maxlength="4" (keyup)="onKeyph($event);"
                  (input)="checkbtn()" required>
              </div>
            </div>
          </div>
          <div class="modal-foot mar-bot-50 pad-bot-20 clearfix">
            <div class="error-txt fnt-12 f500 pad-top-10" *ngIf="binError">
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'first4_last4'">{{GlobalConstants.data.content.bin_error.first4_last4}}</span>
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'first6'">{{GlobalConstants.data.content.bin_error.first6}}</span>
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'first8'">{{GlobalConstants.data.content.bin_error.first8}}</span>
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'last4'">{{GlobalConstants.data.content.bin_error.last4}}</span>
            </div>
            <div class="error-txt fnt-12 f500 pad-top-10" *ngIf="binInvalid">
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'first4_last4'">{{GlobalConstants.data.content.bin_invalid.first4_last4}}</span>
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'first6'">{{GlobalConstants.data.content.bin_invalid.first6}}</span>
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'first8'">{{GlobalConstants.data.content.bin_invalid.first8}}</span>
              <span
                *ngIf="GlobalConstants.data.card_num_type == 'last4'">{{GlobalConstants.data.content.bin_invalid.last4}}</span>
            </div>

            <!-- <button class="btn btn-theme" *ngIf="step==2" (click)="authModal.hide();closeModal()">Submit</button> -->
            <div *ngIf="binError">
              <button class="btn btn-theme relative" type="submit" (click)="restartLogin();"
                [ngStyle]="{'background': GlobalConstants.data.colors.mainBtn_color}">Try Again</button>
            </div>
            <div *ngIf="!binError">
              <button class="btn btn-theme " *ngIf="!btnActive" type="submit" disabled="disabled">Continue</button>
              <button id = "card-continue-btn" class="btn btn-theme relative" *ngIf="btnActive" type="submit"
                [ngStyle]="{'background': GlobalConstants.data.colors.mainBtn_color}">Continue <img *ngIf="isload2"
                  class="loader" src="./assets/img/icons/spin.gif"></button>
            </div>

            <div class="mar-top-30" *ngIf="GlobalConstants.data.personalized_config.has_add_card_text">
              <div class="fnt-13" [ngStyle]="{'color': GlobalConstants.data.colors.active_text_color}">
                {{GlobalConstants.data.content.add_card_text}}</div>
            </div>
          </div>
          <div class="flex last-b">
            <div class="ll">Your Card/ Account parameters are used only to check eligibility and are encrypted</div>
            <div class="rr">
              <img src="./assets/img/logos/PCI.svg">
            </div>

          </div>
        </form>
      </section>
  </div>

  <div *ngIf="showNoCards">
    <div class="modal-content flex" id = "session-closed-modal">
      <section class="heading fnt-20 f600 justify-center align-center flex">
        Session Closed
      </section>
      <section class="body pad-20 mar-top-20 fnt-18 text-center">
        {{showNoCardsMessage}}
      </section>
      <section class="footer pad-20">
        <div class="footer_btns">
          <a class="goTobutton" target="_blank" [ngStyle]="{'background': GlobalConstants.data.colors.mainBtn_color}"
            [href]="GlobalConstants.data.content.autocard_check_card_go_to_link">Apply for Saving Account</a>
          <button class="fnt-16 f500 text-center retry-btn" [ngStyle]="{'background': GlobalConstants.data.colors.mainBtn_color}"
            (click)="showNoCards = false; resetLoginAgain();">Retry</button>
        </div>
      </section>
    </div>
  </div>
</div>
