import { DonationPaymentModalComponent } from './../../shared/donation-payment-modal/donation-payment-modal.component';
import { DonationCardComponent } from './../../shared/donation-card/donation-card.component';
import { MembershipCardsComponent } from './../../shared/membership-cards/membership-cards.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardMaskPipe } from '../../common/pipes/credit-card-mask.pipe';
import { CreditCardDirective } from '../../common/directives/credit-card.directive';
import { IndianCurrency } from '../../common/pipes/indian-currency.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDaterangepickerMd } from '../daterangepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FilterPipe } from '../../common/pipes/filter.pipe';
import { NumbersOnlyDirective } from '../../common/directives/numbers-only.directive';
import { ImagePreloadDirective } from '../../common/pipes/image-preload.directive';
import { CleanHtmlPipe } from '../../common/pipes/clean-html.pipe';
import { RemoveUnderscorePipe } from '../../common/pipes/remove-underscore.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { Time24to12Pipe } from '../../common/pipes/time24to12.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ModalModule } from 'ngx-bootstrap/modal';
import { Template4BannerComponent } from './template4-banner/template4-banner.component';
import { DealsOffersComponent } from './deals-offers/deals-offers.component';
import { RewardPointsComponent } from './reward-points/reward-points.component';
import { SharedModule as commonSharedModule} from 'src/app/pages/shared/shared.module';
import { GiftCardComponent } from '../../shared/gift-card/gift-card.component';
import { FeaturesComponent } from './features/features.component';
import { NewMembershipCardComponent } from './../../shared/new-membership-card/new-membership-card.component';
import { NguCarouselModule } from '@ngu/carousel';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NavHeaderComponent } from '../../shared/nav-header/nav-header.component';
import { CharitySectionComponent } from './charity-section/charity-section.component';
import { LoaderModule } from 'src/app/pages/loader/loader.module';
import { BannerImageComponent } from './banner-image/banner-image.component';

@NgModule({
  declarations: [
    CreditCardMaskPipe,
    CreditCardDirective,
    IndianCurrency,
    FilterPipe,
    NumbersOnlyDirective,
    ImagePreloadDirective,
    CleanHtmlPipe,
    RemoveUnderscorePipe,
    Time24to12Pipe,
    Template4BannerComponent,
    DealsOffersComponent,
    RewardPointsComponent,
    GiftCardComponent,
    MembershipCardsComponent,
    FeaturesComponent,
    NewMembershipCardComponent,
    NavHeaderComponent,
    DonationCardComponent,
    DonationPaymentModalComponent,
    CharitySectionComponent,
    BannerImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    NgxDaterangepickerMd.forRoot({
      firstDay: 0,
    }),
    NgxPaginationModule,
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
    }),
    PopoverModule.forRoot(),
    NgbModule,
    // NgxGalleryModule,
    ModalModule.forRoot(),
    NguCarouselModule,
    CarouselModule.forRoot(),
    LoaderModule
  ],
  exports: [
    CreditCardDirective,
    IndianCurrency,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    NgxDaterangepickerMd,
    NgxPaginationModule,
    ToastrModule,
    FilterPipe,
    NumbersOnlyDirective,
    ImagePreloadDirective,
    CleanHtmlPipe,
    CreditCardMaskPipe,
    RemoveUnderscorePipe,
    PopoverModule,
    Time24to12Pipe,
    NgbModule,
    ModalModule,
    Template4BannerComponent,
    DealsOffersComponent,
    RewardPointsComponent,
    GiftCardComponent,
    MembershipCardsComponent,
    FeaturesComponent,
    NewMembershipCardComponent,
    NavHeaderComponent,
    DonationCardComponent,
    DonationPaymentModalComponent,
    CharitySectionComponent,
    LoaderModule
  ],
  providers :[
    ToastrService
  ]
})
export class SharedModule { }
