import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from 'src/app/pages/common/api.service';
import * as offerActions from '../actions/offers.action';

@Injectable()

export class OffersEffects{
  constructor(
    private actions$: Actions,
    private ApiService: ApiService
  ){}

  loadOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(offerActions.getOffers),
      // exhaustMap((a) =>
      switchMap((action) =>
        this.ApiService.getOffers(action).pipe(
          map(response => offerActions.offersSuccess({res: response, payload: action})),
          catchError((error: any) => of(offerActions.offersFailure(error))))
      )
    ),{dispatch: true}
  );
  loadOfferMerchants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(offerActions.getMerchants),
      // exhaustMap((a) =>
      switchMap((action) =>
        this.ApiService.getMerchants(action).pipe(
          map(response => offerActions.merchantOffersSuccess({res: response, payload: action})),
          catchError((error: any) => of(offerActions.merchantOffersFailure(error))))
      )
    ),{dispatch: true}
  );
  //
  // loadCategoryDetails$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(categoryActions.getCategoryDetails),
  //     // exhaustMap((a) =>
  //     switchMap((action) =>
  //       this.ApiService.getCategoryDetails(action.params).pipe(
  //         map(response => categoryActions.categoryDetailsSuccess(response)),
  //         catchError((error: any) => of(categoryActions.categoryDetailsFailure(error))))
  //     )
  //   ),{dispatch: true}
  // );
}
